import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination } from './core/QueryResponseProvider'
import { KTCard, initialQueryState } from '../../../_metronic/helpers'
import { Table } from '@/app/common/table/Table'
import { ListHeader } from '@/app/common/table/components/header/ListHeader'
import { CustomHeader } from '@/app/common/table/columns/CustomHeader'
import { Column } from 'react-table'
import { ActionsCell } from './table/columns/ActionsCell'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const CardsList = () => {
  const loadedData = useQueryResponseData()
  const { getUserResponse } = useQueryResponse()
  const isLoading = useQueryResponseLoading()
  const pagination = useQueryResponsePagination()
  const { updateState, state } = useQueryRequest()
  const navigate = useNavigate()

  useEffect(() => {
    // get user Id from GET param
    const urlParams = new URLSearchParams(window.location.search)
    const userId = urlParams.get('userId')
    if(!userId) {
      navigate('/error/404');
    }
    updateState({
      createdBy: {
        createdBy: userId
      },
      ...initialQueryState,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: ReadonlyArray<Column<any>> = [ // TODO type
    {
      Header: (props) => <CustomHeader state={state} updateState={updateState} tableProps={props} title='ID' className='min-w-125px' />,
      accessor: 'cardId',
      Cell: ({ ...props }) => props.data[props.row.index].cardId,
    },
    {
      Header: (props) => <CustomHeader state={state} updateState={updateState} tableProps={props} title='Név' className='min-w-125px' />,
      accessor: 'Name',
      Cell: ({ ...props }) => props.data[props.row.index].name,
    },
    {
      Header: (props) => (
        <CustomHeader state={state} updateState={updateState} tableProps={props} title='Létrehozó' className='min-w-125px' />
      ),
      accessor: 'createdByUser',
      Cell: () => <div>{getUserResponse?.firstName + ' ' + getUserResponse?.lastName}</div>,
    },
    {
      Header: (props) => (
        <CustomHeader state={state} updateState={updateState} tableProps={props} title='Kártyatípus' className='min-w-125px' />
      ),
      accessor: 'dC_CardTypeId',
      Cell: ({ ...props }) => props.data[props.row.index].dC_CardType?.name,
    },
    {
      Header: (props) => (
        <CustomHeader  state={state} updateState={updateState} tableProps={props} title='Tevékenység' className='text-end min-w-100px' />
      ),
      id: 'actions',
      disableSortBy: true,
      Cell: ({ ...props }) => <ActionsCell data={props.data[props.row.index]} />,
    },
  ]

  return (
    <>
      <h1 className="h1 page-heading">Kártyák</h1>
      <KTCard>
        <ListHeader showSearch={true} updateState={updateState} />
        <Table pagination={pagination} isLoading={isLoading} updateState={updateState} loadedData={loadedData} columns={columns} />
      </KTCard>
    </>
  )
}

const CardsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CardsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { CardsListWrapper }
