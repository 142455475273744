import {FC, useState, createContext, useContext} from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from '../../../../_metronic/helpers'
import { useAuth } from '../../auth'
import { UserRoleEnum } from '@/app/models/user'

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)
const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const { auth } = useAuth()
  const initQueryState = {...initialQueryRequest.state}
  const queryStateExtension = {createdBy: (auth?.roleId === UserRoleEnum.ADMIN)? undefined : {createdBy: auth?.userId}, needFlags: {needGroupXStudent: true}}
  const extendedQueryState = {...initQueryState, ...queryStateExtension}
  const [state, setState] = useState<QueryState>(extendedQueryState)

  const updateState = (updates: Partial<QueryState>) => {
   
    const updatedState = {...state, ...updates, ...queryStateExtension} as QueryState

    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState, extendedQueryState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
