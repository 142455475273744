import { useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { initialQueryState, KTSVG } from '../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { useDCTables } from '@/app/providers/DCTablesProvider'
import { Option } from '@/app/modules/widgets/components/FormikSelect'


const MyCardPackagesListFilter = () => {
  const { updateState } = useQueryRequest()
  const { isLoading, foldersListResponse, cardPackageCategoriesListResponse } = useQueryResponse()

  const [ folder, setFolder ] = useState<string | undefined>()
  const [ cardPackageCategory, setCardPackageCategory ] = useState<string | undefined>()
  const [ cardPackageType, setCardPackageType ] = useState<string | undefined>()
  const [ cardPackagAccessCategory, setCardPackagAccessCategory ] = useState<string | undefined>()

  const { dcTables } = useDCTables();

  useEffect(() => {
    MenuComponent.reinitialization()

  }, [])

  const resetData = () => {
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    updateState({
      folder: folder ? { FolderIdList: [folder] } : undefined,
      cardPackageCategory: cardPackageCategory ? { CardPackageCategoryIdList: [cardPackageCategory] } : undefined,
      cardPackageType: cardPackageType ? { DC_CardPackageTypeIdList: [cardPackageType] } : undefined,
      cardPackagAccessCategory: cardPackagAccessCategory ? { DC_CardPackageAccessCategoryIdList: [cardPackagAccessCategory] } : undefined,
      ...initialQueryState,
    })
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Szűrés
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Szűrési Opciók</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Mappa:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Kérjük válasszon'
              data-allow-clear='true'
              data-kt-user-table-filter='folder'
              data-hide-search='true'
              onChange={(e) => setFolder(e.target.value)}
              value={folder}
            >
              <option value=''></option>
              {foldersListResponse?.map((item: Option) => (
                <option key={item.id} value={item.id as number}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Kategória:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Kérjük válasszon'
              data-allow-clear='true'
              data-kt-user-table-filter='cardPackageCategory'
              data-hide-search='true'
              onChange={(e) => setCardPackageCategory(e.target.value)}
              value={cardPackageCategory}
            >
              <option value=''></option>
              {cardPackageCategoriesListResponse?.map((item: Option) => (
                <option key={item.id} value={item.id as number}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Csomag típusa:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Kérjük válasszon'
              data-allow-clear='true'
              data-kt-user-table-filter='cardPackageType'
              data-hide-search='true'
              onChange={(e) => setCardPackageType(e.target.value)}
              value={cardPackageType}
            >
              <option value=''></option>
              {dcTables?.dC_CardPackageType?.map((item: Option) => (
                <option key={item.id} value={item.id as number}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Elérhetőség:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Kérjük válasszon'
              data-allow-clear='true'
              data-kt-user-table-filter='cardPackagAccessCategory'
              data-hide-search='true'
              onChange={(e) => setCardPackagAccessCategory(e.target.value)}
              value={cardPackagAccessCategory}
            >
              <option value=''></option>
              {dcTables?.dC_CardPackageAccessCategory?.map((item: Option) => (
                <option key={item.id} value={item.id as number}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>


          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Vissza
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Szűrés
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { MyCardPackagesListFilter }
