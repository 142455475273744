import {FC, useEffect, useMemo, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {ErrorData, ID, QUERIES, errorHandler} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import {useListView} from '../../core/ListViewProvider'
import {ListLoading} from '@/app/common/table/components/loading/ListLoading'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {AxiosError} from 'axios'
import {putItemRequest} from '../../core/_requests'
import {GroupDTO, initialGroupData} from '@/app/models/group'
import {CardPackageDTO} from '@/app/models/card'
import FormikSelect from '@/app/modules/widgets/components/FormikSelect'
import { getItemsRequest as getCardPackagesRequest } from '@/app/modules/my-card-packages/core/_requests'
import { useQuery } from 'react-query'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

type Props = {
  isLoading: boolean
  Data: GroupDTO | undefined
}

const EditGroupModalForm: FC<Props> = ({isLoading, Data}) => {
  const [success, setSuccess] = useState<boolean>(false)
  const [maxCardNumberFlag, setMaxCardNumberFlag] = useState<boolean>(false)
  const [cardSteppLimitFlag, setCardSteppLimitFlag] = useState<boolean>(false)

  const [addedCardPackages, setAddedCardPackages] = useState<CardPackageDTO[]>([])
  const [selectedFolder, setSelectedFolder] = useState<ID | undefined>()

  const {setItemForUpdate} = useListView()
  const {refetch, foldersListResponse, exclusivePackagesResponse} = useQueryResponse()

  const initItemValue = {...initialGroupData}

  const DataForEdit = Data ?? initItemValue

  useEffect(() => {
    if (Data) {
      formik.setValues(Data)

      if(Data.maxCardNumber !== null) {
        setMaxCardNumberFlag(true)
      }

      if(Data.cardSteppLimit !== null) {
        setCardSteppLimitFlag(true)
      }

      const cardPackageIdList = Data?.cardPackageIdList || []
      if(cardPackageIdList?.length > 0) {
        getCardPackagesRequest('CardPackageIdList='+cardPackageIdList.join('&CardPackageIdList=')).then((response) => {
          setAddedCardPackages(response.data ?? []);
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data])

  useEffect(() => {
    formik.setFieldValue('cardPackageIdList', addedCardPackages.map((item) => item.cardPackageId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedCardPackages])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemForUpdate?.(undefined)
  }

  const formik = useFormik({
    initialValues: {...DataForEdit},
    validationSchema: Yup.object().shape({
      groupId: Yup.number(),
      name: Yup.string().when('groupId', {
        is: (val: number | undefined) => val !== 0,
        then: Yup.string().required('A csoport neve kötelező'),
      }),
      isJoinEnableBeforeStart: Yup.boolean(), // Csatlakozás engedélyezése a kezdés előtt
      maxCardNumber: Yup.number().nullable().min(0), // Kártyák számának korlátozása
      isEnableRemainingCards: Yup.boolean(), // Fennmaradó kártyák kiosztásának engedélyezése
      cardSteppLimit: Yup.number().nullable().min(0), // Kártyaléptetési limit
      cardPackageIdList: Yup.array().of(Yup.number()).min(1,'Kártyacsomag kiválasztása kötelező'),
      isStepBackEnable: Yup.boolean(), // Visszalépés engedélyezése
    }),
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        const formData = Object.assign({}, formik.values)

        formData.cardPackageIdList = addedCardPackages.map((item) => item.cardPackageId as number)
        delete formData.cardCount
        delete formData.groupXStudentList
        delete formData.dC_GroupStatus

        if(maxCardNumberFlag === false) {
          formData.maxCardNumber = null;
        }

        if(cardSteppLimitFlag === false) {
          formData.cardSteppLimit = null;
        }

        const {data: updateCardsResponse} = await putItemRequest(formData)

        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          cancel(true)
          resetForm()
        }, 2500)
      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Nem sikerült a mentés, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(err.response.status, err.response.data.errorResponse?.value?.errorInfo || '', err.response.data.errorResponse?.value?.errorDetails || '')
          setStatus(errorText)
          setTimeout(() => {
            setStatus('')
          }, 2500)
        }
      } finally {
        setSubmitting(true)
      }
    },
  })

  const {
    isFetching: folderCardPackagesIsFetching,
    data: folderCardPackagesResponse,
  } = useQuery(
    [`${QUERIES.FOLDER_CARD_PACKAGES}-${selectedFolder}`, selectedFolder],
    () => {
      return getCardPackagesRequest('FolderIdList='+selectedFolder).then((response) => response.data);
    },
    {keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!selectedFolder && selectedFolder !== 'EXCLUSIVE'}
  )

  const availableCardPackages = useMemo(() => {
    if (!folderCardPackagesResponse && !exclusivePackagesResponse) {
      return []
    }

    if(selectedFolder === 'EXCLUSIVE') {
      return exclusivePackagesResponse.filter((item: CardPackageDTO) => {
        return !addedCardPackages.some((addedItem) => addedItem.cardPackageId === item.cardPackageId)
      }) ?? [];
    }

    return folderCardPackagesResponse?.filter((item) => {
      return !addedCardPackages.some((addedItem) => addedItem.cardPackageId === item.cardPackageId)
    }) ?? [];
  }, [folderCardPackagesResponse, addedCardPackages, exclusivePackagesResponse, selectedFolder])

  const cardSum = useMemo(
    () =>
      addedCardPackages?.reduce((acc, item) => {
        return acc + (item?.cardCount ?? 0)
      }, 0),
    [addedCardPackages]
  )

  const handleAddCardPackage = (cardPackage: CardPackageDTO) => {
    setAddedCardPackages([...addedCardPackages, cardPackage])
  }

  const handleRemoveCardPackage = (cardPackage: CardPackageDTO) => {
    setAddedCardPackages(addedCardPackages.filter((item) => item.cardPackageId !== cardPackage.cardPackageId))
  }

  const extendedFolderList = useMemo(() => {
    const list = [...foldersListResponse];
    if (list) {
      list.unshift({id: 'EXCLUSIVE', name: 'Exclusive kártyacsomagok'});
    }
    return list;
  }, [foldersListResponse])

  return (
    <>
      <form
        id='kt_modal_edit_cards_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='row align-items-stretch'>
          <div className='col-12 col-md'>
            <div className='row align-items-stretch mb-3'>
              <div className='col-6'>
                <FormikSelect
                  placeholder='Válasszon mappát'
                  options={extendedFolderList}
                  value={selectedFolder}
                  onChange={(e) => setSelectedFolder(e.target.value)}
                />
              </div>
              <div className='col-6 fw-bold'>
                <div className='form-control form-control-solid fs-6 h-100 d-flex align-items-center'>
                  Csoport tartalma ({cardSum} kártya)
                </div>
              </div>
            </div>

            <div className='row align-items-stretch mb-3'>
              <div className='col-6'>
                <div className='cards-list-wrapper px-0'>
                  <div></div>
                  <div className='cards-list-wrapper__content'>
                    <div className='cards-list-wrapper__content-inner'>
                      { availableCardPackages?.length === 0 && selectedFolder && (
                        <div className='text-center'>Nincs több kártyacsomag</div>
                      )}
                      { availableCardPackages?.length === 0 && !selectedFolder && (
                        <div className='text-center'>Válasszon mappát</div>
                      )}
                      {availableCardPackages.map((item: CardPackageDTO) => (
                      <div onClick={() => handleAddCardPackage(item)} className={`cards-list__card-item`} key={item?.cardPackageId}>
                        <div className='row gx-0 align-items-center flex-nowrap flex-grow-1 mw-100'>
                          <div className='col-auto pe-2'>
                            <i className='la la-boxes fs-2 align-top'></i>
                          </div>
                          <div className='col text-truncate pe-2'>{item?.name}</div>
                          <div className='col-auto'>
                            <button type='button' className='btn btn-icon btn-sm'>
                              <i className='la la-plus fs-3'></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      ))}
                    </div>
                  </div>
                  <div className='cards-list-wrapper__footer'></div>
                </div>
              </div>
              <div className='col-6'>
                <div className='cards-list-wrapper px-0'>
                  <div></div>
                  <div className='cards-list-wrapper__content'>
                    <div className='cards-list-wrapper__content-inner'>
                      { addedCardPackages?.length === 0 && (
                        <div className='text-center'>Nincs hozzáadott kártyacsomag</div>
                      )}
                      {addedCardPackages.map((item) => (
                      <div onClick={() => handleRemoveCardPackage(item)} className={`cards-list__card-item`} key={item?.cardPackageId}>
                        <div className='row gx-0 align-items-center flex-nowrap flex-grow-1 mw-100'>
                          <div className='col-auto pe-2'>
                            <i className='la la-boxes fs-2 align-top'></i>
                          </div>
                          <div className='col text-truncate pe-2'>{item?.name}</div>
                          <div className='col-auto'>
                            <button type='button' className='btn btn-icon btn-sm'>
                              <i className='la la-minus fs-3'></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      ))}
                    </div>
                  </div>
                  <div className='cards-list-wrapper__footer'></div>
                </div>
              </div>
            </div>

            {formik.touched.cardPackageIdList && formik.errors.cardPackageIdList && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.cardPackageIdList}</span>
                          </div>
                        </div>
                      )}
          </div>

          <div className='col-12 col-md'>
            {/* begin::Scroll */}
            <div className='d-flex flex-column me-n7 pe-7'>
              <div className='fv-row mb-8'>
                <div className='row g-4'>
                  { Data?.groupId && (
                    <div className='col-12'>
                      <label className='fs-6 mb-2 required'>Csoport neve</label>
                      {/*  */}
                      <input
                        placeholder='Csoport neve'
                        {...formik.getFieldProps(`name`)}
                        type='text'
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {'is-invalid': formik.touched.name && formik.errors.name},
                          {
                            'is-valid': formik.touched.name && !formik.errors.name,
                          }
                        )}
                        autoComplete='off'
                        disabled={formik.isSubmitting || isLoading}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.name}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className='col-12'>
                    <label className='fs-6 fw-bold'>Kártyakiosztás</label>
                  </div>

                  <div className='col-12'>
                    <label className='form-check form-check-inline'>
                      <input
                        className='form-check-input w-20px h-20px'
                        type='checkbox'
                        id='isJoinEnableBeforeStart'
                        checked={formik.values.isJoinEnableBeforeStart}
                        {...formik.getFieldProps('isJoinEnableBeforeStart')}
                      />
                      <label htmlFor='isJoinEnableBeforeStart' className='form-check-label'>
                        Csatlakozás engedélyezése indítás előtt
                        <OverlayTrigger
                          key='info-isJoinEnableBeforeStart'
                          placement='top'
                          overlay={<Tooltip id='tooltip-isJoinEnableBeforeStart'>A tanulók beléphetnek a csoportba a csoport elindítása előtt.</Tooltip>}
                        >
                          <button type='button' className='btn btn-icon btn-sm ms-2' style={{height: 17}}>
                            <i className="la la-info-circle fs-4"></i>
                          </button>
                        </OverlayTrigger>
                      </label>
                    </label>
                  </div>

                  <div className='col-12'>
                    <div className='row align-items-center'>
                      <div className='col-auto'>
                        <label className='form-check form-check-inline'>
                          <input
                            className='form-check-input w-20px h-20px'
                            type='checkbox'
                            id='maxCardNumberFlag'
                            checked={maxCardNumberFlag}
                            onChange={(e) => setMaxCardNumberFlag(e.target.checked)}
                          />
                          <label className='form-check-label' htmlFor='maxCardNumberFlag'>
                            Kártyák számának korlátozása
                            <OverlayTrigger
                              key='info-maxCardNumberFlag'
                              placement='top'
                              overlay={<Tooltip id='tooltip-maxCardNumberFlag'>Maximum ennyi kártya lesz felhasználva a hozzáadott kártyacsomagokból. </Tooltip>}
                            >
                              <button type='button' className='btn btn-icon btn-sm ms-2' style={{height: 17}}>
                                <i className="la la-info-circle fs-4"></i>
                              </button>
                            </OverlayTrigger>
                          </label>
                        </label>
                      </div>
                      <div className='col'>
                        <input
                          placeholder='Kártyák száma'
                          {...formik.getFieldProps(`maxCardNumber`)}
                          type='number'
                          min={0}
                          style={{width: 120}}
                          className={clsx(
                            'form-control form-control-solid mb-3 mb-lg-0',
                            {
                              'is-invalid':
                                formik.touched.maxCardNumber && formik.errors.maxCardNumber,
                            },
                            {
                              'is-valid':
                                formik.touched.maxCardNumber && !formik.errors.maxCardNumber,
                            }
                          )}
                          autoComplete='off'
                          disabled={formik.isSubmitting || isLoading || !maxCardNumberFlag}
                        />
                        {formik.touched.maxCardNumber && formik.errors.maxCardNumber && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.maxCardNumber}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='col-12'>
                    <label className='form-check form-check-inline'>
                      <input
                        className='form-check-input w-20px h-20px'
                        type='checkbox'
                        id='isEnableRemainingCards'
                        checked={formik.values.isEnableRemainingCards}
                        {...formik.getFieldProps('isEnableRemainingCards')}
                      />
                      <label htmlFor='isEnableRemainingCards' className='form-check-label'>
                        Fennmaradó kártyák kiosztásának engedélyezése
                        <OverlayTrigger
                              key='info-isEnableRemainingCards'
                              placement='top'
                              overlay={<Tooltip id='tooltip-isEnableRemainingCards'>Alapbeállításként minden tanulónak ugyanannyi kártya kerül kiosztásra, és az osztás után fennmaradó kártyák nem kerülnek játékba. Ezzel a beállítással ezek a kártyák is kiosztásra kerülnek, így lesz, akinek több kártya jut.</Tooltip>}
                            >
                              <button type='button' className='btn btn-icon btn-sm ms-2' style={{height: 17}}>
                                <i className="la la-info-circle fs-4"></i>
                              </button>
                            </OverlayTrigger>
                      </label>
                    </label>
                  </div>

                  <div className='col-12'>
                    <div className='row align-items-center'>
                      <div className='col-auto'>
                        <label className='form-check form-check-inline'>
                          <input
                            className='form-check-input w-20px h-20px'
                            type='checkbox'
                            id='cardSteppLimitFlag'
                            checked={cardSteppLimitFlag}
                            onChange={(e) => setCardSteppLimitFlag(e.target.checked)}
                          />
                          <label className='form-check-label' htmlFor='cardSteppLimitFlag'>
                            Kártyaléptetés engedélyezése
                            <OverlayTrigger
                              key='info-cardSteppLimitFlag'
                              placement='top'
                              overlay={<Tooltip id='tooltip-cardSteppLimitFlag'>Alapbeállításként a tanuló 30 másodperc múlva léphet a következő kártyára. Ezzel a beállítással ezt az időt lehet felülírni.</Tooltip>}
                            >
                              <button type='button' className='btn btn-icon btn-sm ms-2' style={{height: 17}}>
                                <i className="la la-info-circle fs-4"></i>
                              </button>
                            </OverlayTrigger>
                          </label>
                        </label>
                      </div>
                      <div className='col'>
                        <input
                          placeholder='Lépések száma'
                          {...formik.getFieldProps(`cardSteppLimit`)}
                          type='number'
                          min={0}
                          style={{width: 120}}
                          className={clsx(
                            'form-control form-control-solid mb-3 mb-lg-0',
                            {
                              'is-invalid':
                                formik.touched.cardSteppLimit && formik.errors.cardSteppLimit,
                            },
                            {
                              'is-valid':
                                formik.touched.cardSteppLimit && !formik.errors.cardSteppLimit,
                            }
                          )}
                          autoComplete='off'
                          disabled={formik.isSubmitting || isLoading || !cardSteppLimitFlag}
                        />
                        {formik.touched.cardSteppLimit && formik.errors.cardSteppLimit && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.cardSteppLimit}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='col-12'>
                    <label className='form-check form-check-inline'>
                      <input
                        className='form-check-input w-20px h-20px'
                        type='checkbox'
                        id='isStepBackEnable'
                        checked={formik.values.isStepBackEnable}
                        {...formik.getFieldProps('isStepBackEnable')}
                      />
                      <label htmlFor='isStepBackEnable' className='form-check-label'>
                        Visszalépés engedélyezése
                        <OverlayTrigger
                              key='info-isStepBackEnable'
                              placement='top'
                              overlay={<Tooltip id='tooltip-isStepBackEnable'>A tanuló visszaléphet a már felhasznált kártyákra.</Tooltip>}
                            >
                              <button type='button' className='btn btn-icon btn-sm ms-2' style={{height: 17}}>
                                <i className="la la-info-circle fs-4"></i>
                              </button>
                            </OverlayTrigger>
                      </label>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* end::Scroll */}
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6 offset-lg-6'>
            {formik.status && (
              <div className='alert alert-danger mt-6'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
            {success && (
              <div className='mb-10 bg-light-success p-4 rounded mt-6'>
                <div className='text-success'>Sikeres mentés!</div>
              </div>
            )}

            {/* begin::Actions */}
            <div className='text-center pt-15'>
              <button
                type='reset'
                onClick={() => cancel()}
                className='btn btn-light me-3'
                data-kt-edit-cards-modal-action='cancel'
                disabled={formik.isSubmitting}
              >
                Mégse
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                data-kt-edit-cards-modal-action='submit'
                disabled={formik.isSubmitting || !formik.touched}
              >
                <span className='indicator-label'>Mentés</span>
                {formik.isSubmitting && (
                  <span className='indicator-progress'>
                    Kérem várjon...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <ListLoading />}
    </>
  )
}

export {EditGroupModalForm}
