import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination } from './core/QueryResponseProvider'
import { KTCard } from '../../../_metronic/helpers'
import { EditOrCreateModal } from './edit-or-create-modal/EditOrCreateModal'
import { Table } from '@/app/common/table/Table'
import { ListHeader } from '@/app/common/table/components/header/ListHeader'
import { ListToolbar } from './components/header/ListToolbar'
import { CustomHeader } from '@/app/common/table/columns/CustomHeader'
import { Column } from 'react-table'
import { ActionsCell } from './table/columns/ActionsCell'

const PackageCategoriesList = () => {
  const { itemForUpdate } = useListView()
  const loadedData = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const pagination = useQueryResponsePagination()
  const { updateState, state } = useQueryRequest()

  const columns: ReadonlyArray<Column<any>> = [ // TODO type
    {
      Header: (props) => <CustomHeader state={state} updateState={updateState} tableProps={props} title='Név' className='min-w-125px' />,
      accessor: 'Name',
      Cell: ({ ...props }) => props.data[props.row.index].name,
    },
    {
      Header: (props) => (
        <CustomHeader state={state} updateState={updateState} tableProps={props} title='Csomagok száma' className='min-w-125px' />
      ),
      accessor: 'PackageCount',
      Cell: ({ ...props }) => props.data[props.row.index].cardPackageCount,
    },
    {
      Header: (props) => (
        <CustomHeader  state={state} updateState={updateState} tableProps={props} title='Tevékenység' className='text-end min-w-100px' />
      ),
      id: 'actions',
      disableSortBy: true,
      Cell: ({ ...props }) => <ActionsCell data={props.data[props.row.index]} />,
    },
  ]

  return (
    <>
      <h1 className="h1 page-heading">Csomag kategóriák</h1>
      <KTCard>
        <ListHeader showSearch={true} updateState={updateState} toolbarComponent={<ListToolbar/>} />
        <Table pagination={pagination} isLoading={isLoading} updateState={updateState} loadedData={loadedData} columns={columns} />
      </KTCard>
      {itemForUpdate !== undefined && <EditOrCreateModal />}
    </>
  )
}

const PackageCategoriesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PackageCategoriesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { PackageCategoriesListWrapper }
