import {EditCardsModalHeader} from './EditCardsModalHeader'
import {EditCardsModalFormWrapper} from './EditCardsModalFormWrapper'

const EditCardsModal = () => {

  return (
    <>
      <div
        className='modal modal-xl fade show d-block'
        id='kt_modal_card_edit'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-1200px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <EditCardsModalHeader />
            {/* begin::Modal body */}
            <div className='modal-body scroll-y'>
              <EditCardsModalFormWrapper />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {EditCardsModal}
