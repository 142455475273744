import {FC, useEffect, useMemo, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {ErrorData, QUERIES, errorHandler} from '../../../../../../_metronic/helpers'
import {RightEnum, User, initialUser, userRoleList} from '@/app/models/user'
import clsx from 'clsx'
import {useListView} from '../../core/ListViewProvider'
import {UsersListLoading} from '../../components/loading/UsersListLoading'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {AxiosError} from 'axios'
import {deleteUser, putUser} from '../../core/_requests'
import {sha256} from 'crypto-hash'
import {useMutation, useQueryClient} from 'react-query'
import FormikSelect from '@/app/modules/widgets/components/FormikSelect'
import { useDCTables} from '@/app/providers/DCTablesProvider'
import Swal from 'sweetalert2'
import {useAuth} from '@/app/modules/auth'

type Props = {
  isUserLoading: boolean
  User: User | undefined
  altCancel?: () => void
}

const globalUserSchema = {
  emailAddress: Yup.string()
    .email('Rossz e-mail formátum')
    .min(3, 'Minimum 3 karakter.')
    .max(50, 'Maximum 50 karakter.')
    .required('Kötelező mező'),
  firstName: Yup.string()
    .min(3, 'Minimum 3 karakter.')
    .max(50, 'Maximum 50 karakter.')
    .required('Kötelező mező'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 karakter.')
    .max(50, 'Maximum 50 karakter.')
    .required('Kötelező mező'),
  roleId: Yup.string().required('Kötelező mező'),
  dC_CountryId: Yup.string().required('Kötelező mező'),
  isActive: Yup.boolean().required('Kötelező mező'),
  password: Yup.string(),
}

const editUserSchema = Yup.object().shape(globalUserSchema)
const createUserSchema = Yup.object().shape({
  ...globalUserSchema,
  password: Yup.string().min(3, 'Minimum 3 karakter.').required('Kötelező mező'),
})

const UserEditModalForm: FC<Props> = ({User, isUserLoading, altCancel}) => {
  const [success, setSuccess] = useState<boolean>(false)

  const UserForEdit = User ? {dC_CountryId: '', ...User} : {...initialUser}

  useEffect(() => {
    if (User) {
      formik.setValues({dC_CountryId: '', ...User})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [User])

  const isNewUser = !User?.userId

  const {setItemForUpdate} = useListView()
  const {refetch, query} = useQueryResponse()
  const queryClient = useQueryClient()

  const {dcTables} = useDCTables()

  const {currentUserCan, auth, logout} = useAuth()

  const countryList = useMemo(() => {
    return dcTables?.dC_Country ?? []
  }, [dcTables])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    altCancel?.()
    setItemForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: UserForEdit,
    validationSchema: !isNewUser ? editUserSchema : createUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        const user = Object.assign(UserForEdit, formik.values)
        const sha256Pw = await sha256(user.password || '')
        user.phoneNumber = ''
        user.aszfAccepted = true

        if (!User?.userId) {
          user.password = sha256Pw
        }

        const {data} = await putUser(user)

        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          cancel(true)
          resetForm()
        }, 2500)
      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Nem sikerült a mentés, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(err.response.status, err.response.data.errorResponse?.value?.errorInfo || '', err.response.data.errorResponse?.value?.errorDetails || '')
          setStatus(errorText)
          setTimeout(() => {
            setStatus('')
          }, 2500)
        }
      } finally {
        setSubmitting(true)
      }
    },
  })

  const handleDeleteClick = async () => {
    const result = await Swal.fire({
      title: 'Biztos, hogy törölni szeretnéd a felhasználói fiókot?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Törlés',
      cancelButtonText: 'Mégse',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary',
      },
    })

    if (result.isConfirmed) {
      await deleteItem.mutateAsync()
    }
  }

  const deleteItem = useMutation(() => deleteUser(User?.userId), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      cancel(true)

      if(User?.userId === auth?.userId) {
        logout()
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_User_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div className='d-flex flex-column me-n7 pe-7'>
          <div className='fv-row mb-8'>
            <div className='row g-4'>
              <div className='col-lg-6'>
                <label className='fs-6 mb-2 required'>Vezetéknév</label>
                {/* end::Label */}
                {/* begin::Input */}
                <input
                  placeholder='Vezetéknév'
                  {...formik.getFieldProps('lastName')}
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.lastName && formik.errors.lastName},
                    {
                      'is-valid': formik.touched.lastName && !formik.errors.lastName,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.lastName}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-lg-6'>
                <label className='fs-6 mb-2 required'>Keresztnév</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Keresztnév'
                  {...formik.getFieldProps('firstName')}
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.firstName && formik.errors.firstName},
                    {
                      'is-valid': formik.touched.firstName && !formik.errors.firstName,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.firstName}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-lg-6'>
                <label className='fs-6 mb-2 required'>E-mail</label>
                {/* end::Label */}
                {/* begin::Input */}
                <input
                  placeholder='E-mail'
                  {...formik.getFieldProps('emailAddress')}
                  type='email'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.emailAddress && formik.errors.emailAddress},
                    {
                      'is-valid': formik.touched.emailAddress && !formik.errors.emailAddress,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.emailAddress && formik.errors.emailAddress && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.emailAddress}</span>
                    </div>
                  </div>
                )}
              </div>

              {isNewUser && (
                <div className='col-lg-6'>
                  <label className='fs-6 mb-2 required'>Jelszó</label>
                  {/* end::Label */}
                  {/* begin::Input */}
                  <input
                    placeholder='Jelszó'
                    {...formik.getFieldProps('password')}
                    type='password'
                    name='password'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.password && formik.errors.password},
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isUserLoading}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
              { currentUserCan(RightEnum.UserEditing) && (
                <div className='col-lg-6'>
                  <FormikSelect
                    labelText='Szerepkör'
                    selectProps={{...formik.getFieldProps('roleId')}}
                    options={userRoleList.map((item) => {
                      return {
                        id: item.id.toString(),
                        name: item.name,
                      }
                    })}
                    formikTouched={formik.touched.roleId}
                    formikErrors={formik.errors.roleId}
                  />
                </div>
              )}

              <div className='col-lg-6'>
                <FormikSelect
                  labelText='Ország'
                  selectProps={{...formik.getFieldProps('dC_CountryId')}}
                  options={countryList ?? []}
                  formikTouched={formik.touched.dC_CountryId}
                  formikErrors={formik.errors.dC_CountryId}
                />
              </div>

              {(currentUserCan(RightEnum.UserEditing)) && (
              <div className='col-lg-6'>
                <FormikSelect
                  labelText='Státusz'
                  //selectProps={{...formik.getFieldProps('isActive')}}
                  value={formik.values.isActive ? 'active' : 'inactive'}
                  onChange={(e) => formik.setFieldValue('isActive', e.target.value === 'active')}
                  options={[
                    {id: 'active', name: 'Aktív'},
                    {id: 'inactive', name: 'Inaktív'},
                  ]}
                  formikTouched={formik.touched.isActive}
                  formikErrors={formik.errors.isActive}
                />
              </div>
              )}
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {formik.status && (
          <div className='alert alert-danger mt-6'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        {success && (
          <div className='mb-10 bg-light-success p-4 rounded mt-6'>
            <div className='text-success'>Sikeres profil mentés!</div>
          </div>
        )}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          {!isNewUser && (
            <button
              type='button'
              onClick={handleDeleteClick}
              className='btn btn-danger me-3'
              disabled={formik.isSubmitting || isUserLoading}
            >
              Törlés
            </button>
          )}

          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Mégse
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.touched}
          >
            <span className='indicator-label'>Mentés</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Kérem várjon...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {UserEditModalForm}
