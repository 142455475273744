import { useQuery } from 'react-query'
import { isNotEmpty} from '../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { getCardsByCardPackageIdRequest } from '../core/_requests'
import { EditCardsModalForm } from './content/EditCardsModalForm'

const EditCardsModalFormWrapper = () => {
  const { itemForView, setItemForView } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemForView) && itemForView?.cardPackageId !== 0

  const {
    isLoading,
    data,
    refetch: refetchCards,
    error,
  } = useQuery(
    `edit-card-package-cards-${itemForView?.cardPackageId}`,
    () => {
      return getCardsByCardPackageIdRequest(itemForView?.cardPackageId)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err) => {
        setItemForView?.(undefined)
        console.error(err)
      },
    }
  )

  return <EditCardsModalForm isLoading={isLoading} refetchCards={refetchCards} Data={data}/>
}

export { EditCardsModalFormWrapper }
