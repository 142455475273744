import { QueryState } from '../../../../../_metronic/helpers'
import {ListSearchComponent} from './ListSearchComponent'

type Props = {
  showSearch?: boolean | undefined
  toolbarComponent?: React.ReactNode | undefined
  updateState: (updates: Partial<QueryState>) => void
}

const ListHeader = ({showSearch, toolbarComponent, updateState}: Props) => {
  return (
    <div className='card-header border-0 pt-6'>
      { showSearch && <ListSearchComponent updateState={updateState} />}
      {/* begin::Card toolbar */}
      { toolbarComponent && <div className='card-toolbar'>{toolbarComponent}</div> }
      {/* end::Card toolbar */}
    </div>
  )
}

export {ListHeader}
