import { useMemo } from 'react'
import { useTable, ColumnInstance, Row, Column } from 'react-table'
import { CustomHeaderColumn } from '../table/columns/CustomHeaderColumn'
import { CustomRow } from '../table/columns/CustomRow'
import { ListLoading } from './components/loading/ListLoading'
import { ListPagination } from './components/pagination/ListPagination'
import { KTCardBody, PaginationState, QueryState } from '../../../_metronic/helpers'

type Props = {
  pagination: PaginationState
  isLoading: boolean
  updateState: (updates: Partial<QueryState>) => void
  loadedData: any
  columns: ReadonlyArray<Column<any>>
}

const Table = ({isLoading, loadedData, pagination, updateState, columns}: Props) => {
  const data = useMemo(() => loadedData, [loadedData])
  const columnsMemo = useMemo(() => columns, [columns])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns: columnsMemo,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table table-striped align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<any>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>

          <tbody className='text-gray-700' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Nincs megjeleníthető találat
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ListPagination pagination={pagination} updateState={updateState} isLoading={isLoading} />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export { Table }
