/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { PaginationState, QueryState, isActiveButtons } from '../../../../../_metronic/helpers'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Előző') {
    return 'Előző'
  }

  if (label === 'Következő &raquo;') {
    return 'Következő'
  }

  return label
}

type Props = {
  pagination: PaginationState
  isLoading: boolean
  updateState: (updates: Partial<QueryState>) => void
}

const ListPagination = ({pagination, isLoading, updateState}: Props) => {

  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({ page, ['per-page']: pagination['per-page'] || 10 })
  }

  const actualPage = pagination?.links?.findIndex(item => item?.is_active == true)
  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            <li
              key={"Previous"}
              className={clsx('page-item', {
                active: false,
                disabled: isLoading,
                previous: true,
                next: false,
              })}
            >
              <a
                className={clsx('page-link', {
                  'page-text': true || false,
                  'me-5': true,
                })}
                onClick={() => updatePage(Number(pagination.page) > 1 ? Number(pagination.page) - 1 : null)}
                style={{ cursor: 'pointer' }}
              >
                {mappedLabel("Előző")}
              </a>
            </li>
            {pagination.links
              ?.map((link,index) => (
                <li
                  key={link.url}
                  className={clsx('page-item', {
                    active: Number(pagination.page) == index+1,
                    disabled: isLoading,
                    'd-none': !isActiveButtons(Number(pagination.page),index)
                  })}
                >
                  <a
                    className={clsx('page-link')}
                    onClick={() => updatePage(index+1)}
                    style={{ cursor: 'pointer' }}
                  >
                   {index+1}
                  </a>
                </li>
              ))}
            <li
              className={clsx('page-item', {
                active: false,
                disabled: isLoading,
                previous: false,
                next: true,
              })}
            >
              <a
                className={clsx('page-link', {
                  'page-text': false || true,
                  'me-5': false,
                })}
                onClick={() => updatePage((pagination?.links?.length) as number > Number(pagination.page) ? Number(pagination.page) + 1 : null)}
                style={{ cursor: 'pointer' }}
              >
                {mappedLabel("Következő")}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export { ListPagination }
