import moment from 'moment'
import { Dispatch, SetStateAction } from 'react'
import { User } from '@/app/models/user'

export type ID = undefined | null | number | string

export type PaginationState = {
  page: number
  "per-page": 10 | 30 | 50 | 100
  links?: Array<{ label: string; is_active: boolean, active: boolean; url: string | null; page: number | null }>
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type DeletedState = {
  isDeleted?: boolean
}

export type FilterState = {
  filter?: unknown
}

export type StatusState = {
  status?: unknown
}

export type BusinessRole = {
  role?: unknown
}

export type SearchState = {
  search?: string
}

export type CreatedByState = {
  createdBy?: unknown
}

export type NeedFlagsState = {
  needFlags?: unknown
}

export type CardPackage = {
  folder?: unknown
  cardPackageCategory?: unknown
  cardPackageType?: unknown
  cardPackagAccessCategory?: unknown
  cardPackage?: unknown
}

export type Response<T> = {
  data?: T
  errorResponse?: {
    contentType: string | null
    serializerSettings: string | null
    statusCode: number
    value: {
      errorDetails: string | null
      errorInfo: string | null
      result: string
      errorResponse: any
    } | null
  }
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & DeletedState & SearchState & StatusState & BusinessRole & CardPackage & CreatedByState & NeedFlagsState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
  extendedQueryState?: QueryState
}

export const initialQueryState: QueryState = {
  page: 1,
  "per-page": 10,
  isDeleted: false,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => { },
  extendedQueryState: initialQueryState
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type QueryResponseContextPropsNonArray<T> = {
  response?: Response<T> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '' }

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
}

export type HeaderUserListViewContextProps = {
  itemForUpdate?: User
  setItemForUpdate: Dispatch<SetStateAction<User | undefined>>
}


export type UserListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  orderModal?: boolean
  setOrderModal: Dispatch<SetStateAction<boolean>>
  itemForUpdate?: User
  setItemForUpdate: Dispatch<SetStateAction<User | undefined>>
  itemForView?: User
  setItemForView: Dispatch<SetStateAction<User | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export type GeneralListViewContextProps<T> = {
  selected?: Array<ID>
  orderModal?: boolean
  setOrderModal: Dispatch<SetStateAction<boolean>>
  onSelect?: (selectedId: ID) => void
  onSelectAll?: () => void
  clearSelected?: () => void
  itemForUpdate?: T
  setItemForUpdate?: Dispatch<SetStateAction<T | undefined>>
  itemForView?: T
  setItemForView?: Dispatch<SetStateAction<T | undefined>>
  isAllSelected?: boolean
  disabled?: boolean
}

export const initialGeneralOrderListView: GeneralListViewContextProps<unknown> = {
  selected: [],
  orderModal: false,
  setOrderModal: () => { },
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForView: () => { },
}

export const initialHeaderListView: HeaderUserListViewContextProps = {
  setItemForUpdate: () => { },
}


export const initialOrderListView: UserListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForView: () => { },
  setOrderModal: () => { }
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
}

export interface PaginationProps {
  pageIndex: number
  pageOptions: number[]
  nextPage: () => void
  previousPage: () => void
  canNextPage: boolean,
  canPreviousPage: boolean
  setPageSize: (arg0: number) => void;
  gotoPage: (arg0: number) => void;
}

export type CustomQueryResponseContextProps<T> = {
  response?: Array<T> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}


export type ResponseProductCategoryItemModel = {
  id: string,
  name: string
}


export type optionItemForMultiSelect = {
  value: string,
  label: string
}

export type ListView = {
  id?: number;
  name?: string;
  code?: string
}

export type VatList = {
  id?: number;
  name?: string;
  vat_amount?: string;
  vat?: string;
}

export type ErrorData = {
  errorDetails: string
  errorInfo: string
  result: string
  errorResponse?: {
    contentType: string | null
    serializerSettings: string | null
    statusCode: number
    value: {
      errorDetails: string | null
      errorInfo: string | null
      result: string
      errorResponse: any
    } | null
  }
}

export type WindowWidthHeightModel = {
  width: number | undefined;
  height: number | undefined;
}

