import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {ErrorData, QUERIES, errorHandler} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import {useListView} from '../../core/ListViewProvider'
import {ListLoading} from '@/app/common/table/components/loading/ListLoading'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {AxiosError} from 'axios'
import {deleteItemRequest, putItemRequest} from '../../core/_requests'
import {useMutation, useQueryClient} from 'react-query'
import Swal from 'sweetalert2'
import {CardPackageDTO, initialCardPackageData} from '@/app/models/card'
import {DCList, useDCTables} from '@/app/providers/DCTablesProvider'
import {AddFolderModal} from '../AddFolderModal'
import FormikSelect from '@/app/modules/widgets/components/FormikSelect'
import {RightEnum} from '@/app/models/user'
import {useAuth} from '@/app/modules/auth'

type Props = {
  isUserLoading: boolean
  Data: CardPackageDTO | undefined
}

const EditModalForm: FC<Props> = ({Data, isUserLoading}) => {
  const [success, setSuccess] = useState<boolean>(false)
  const [addFolderOpened, setAddFolderOpened] = useState<boolean>(false)
  const {dcTables} = useDCTables()
  const {currentUserCan} = useAuth()

  let DataForEdit = Data ?? {...initialCardPackageData}

  useEffect(() => {
    if (Data) {
      formik.setValues(Data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data])

  const isNew = !Data?.cardPackageCategoryId

  const {setItemForUpdate} = useListView()
  const {
    refetch,
    query,
    foldersListResponse,
    foldersListRefetch,
    cardPackageCategoriesListResponse,
  } = useQueryResponse()
  const queryClient = useQueryClient()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemForUpdate?.(undefined)
  }

  const formik = useFormik({
    initialValues: DataForEdit,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, 'Minimum 2 karakter.')
        .max(50, 'Maximum 50 karakter.')
        .required('Kötelező mező'),
      cardPackageCategoryId: Yup.string().required('Kötelező mező'),
      dC_CardPackageAccessCategoryId: Yup.string().required('Kötelező mező'),
      dC_CardPackageTypeId: Yup.string().required('Kötelező mező'),
      description: Yup.string(),
      folderId: Yup.string(),
    }),
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        const formData = Object.assign(DataForEdit, formik.values)

        const {data} = await putItemRequest(formData)

        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          cancel(true)
          resetForm()
        }, 2500)
      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Nem sikerült a mentés, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(err.response.status, err.response.data.errorResponse?.value?.errorInfo || '', err.response.data.errorResponse?.value?.errorDetails || '')
          setStatus(errorText)
          setTimeout(() => {
            setStatus('')
          }, 2500)
        }
      } finally {
        setSubmitting(true)
      }
    },
  })

  const handleDeleteClick = async () => {
    const result = await Swal.fire({
      title: 'Biztos, hogy törölni szeretnéd a kártya csomagot?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Törlés',
      cancelButtonText: 'Mégse',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary',
      },
    })

    if (result.isConfirmed) {
      await deleteItem.mutateAsync()
    }
  }

  const deleteItem = useMutation(() => deleteItemRequest(Data?.cardPackageCategoryId), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CATEGORIES_LIST}-${query}`])
      cancel(true)
    },
  })

  return (
    <>
      <form
        id='kt_modal_add_my_card_packages_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div className='d-flex flex-column me-n7 pe-7'>
          <div className='fv-row mb-8'>
            <div className='row g-4'>
              <div className='col-12'>
                <label className='fs-6 mb-2 required'>Csomag neve</label>
                {/* end::Label */}
                {/* begin::Input */}
                <input
                  placeholder='Csomag neve'
                  {...formik.getFieldProps('name')}
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-12'>
                <FormikSelect
                  labelText='Kategória'
                  selectProps={{...formik.getFieldProps('cardPackageCategoryId')}}
                  options={cardPackageCategoriesListResponse}
                  formikTouched={formik.touched.cardPackageCategoryId}
                  formikErrors={formik.errors.cardPackageCategoryId}
                />
              </div>
              {currentUserCan(RightEnum.ExclusivePackageCreation) && (
                <div className='col-12'>
                  <FormikSelect
                    labelText='Csomag típusa'
                    selectProps={{...formik.getFieldProps('dC_CardPackageTypeId')}}
                    options={dcTables?.dC_CardPackageType.map((item: DCList) => {
                      return {
                        id: item.id.toString(),
                        name: item.name,
                      }
                    })}
                    formikTouched={formik.touched.dC_CardPackageTypeId}
                    formikErrors={formik.errors.dC_CardPackageTypeId}
                  />
                </div>
              )}
              {currentUserCan(RightEnum.CardPrivatePackages) && (
              <div className='col-12'>
                <FormikSelect
                  labelText='Elérhetőség'
                  selectProps={{...formik.getFieldProps('dC_CardPackageAccessCategoryId')}}
                  options={dcTables?.dC_CardPackageAccessCategory.map((item: DCList) => {
                    return {
                      id: item.id.toString(),
                      name: item.name,
                    }
                  })}
                  formikTouched={formik.touched.dC_CardPackageAccessCategoryId}
                  formikErrors={formik.errors.dC_CardPackageAccessCategoryId}
                />
              </div>
              )}

              <div className='col-12'>
                <label className='fs-6 mb-2'>Rövid Leírás</label>
                {/* end::Label */}
                {/* begin::Input */}
                <input
                  placeholder='Rövid Leírás'
                  {...formik.getFieldProps('description')}
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.description && formik.errors.description},
                    {
                      'is-valid': formik.touched.description && !formik.errors.description,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.description}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-12'>
                <label className='fs-6 mb-2'>Mappa</label>
                <div className='row gx-2 align-items-center'>
                  <div className='col'>
                    <FormikSelect
                      selectProps={{...formik.getFieldProps('folderId')}}
                      options={foldersListResponse}
                      formikTouched={formik.touched.folderId}
                      formikErrors={formik.errors.folderId}
                    />
                  </div>
                  <div className='col-auto'>
                    <button
                      type='button'
                      className='btn btn-icon btn-active-light-primary'
                      onClick={() => setAddFolderOpened(true)}
                    >
                      <i className='la la-folder-plus fs-2'></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {formik.status && (
          <div className='alert alert-danger mt-6'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        {success && (
          <div className='mb-10 bg-light-success p-4 rounded mt-6'>
            <div className='text-success'>Sikeres mentés!</div>
          </div>
        )}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          {!isNew && (
            <button
              type='button'
              onClick={handleDeleteClick}
              className='btn btn-danger me-3'
              disabled={formik.isSubmitting || isUserLoading}
            >
              Törlés
            </button>
          )}

          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-my-card-packages-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Mégse
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-my-card-packages-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.touched}
          >
            <span className='indicator-label'>Mentés</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Kérem várjon...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <ListLoading />}

      {addFolderOpened && (
        <AddFolderModal onSave={foldersListRefetch} onClose={() => setAddFolderOpened(false)} />
      )}
    </>
  )
}

export {EditModalForm}
