/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {KTSVG, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {importItemRequest} from '../../core/_requests'
import { CardPackageDTO, CardPackageTypeEnum } from '@/app/models/card'

type Props = {
  data: CardPackageDTO
}

const ActionsCell: FC<Props> = ({data}) => {
  const {setItemForUpdate, setItemForView} = useListView()
  const {query, refetch} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openViewModal = () => {
    setItemForUpdate?.(data)
  }

  const openCardsModal = () => {
    setItemForView?.(data)
  }

  const duplicateItem = useMutation(() => importItemRequest(data?.cardPackageId), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CARD_PACKAGES}-${query}`])
    },
  })


  return (
    <>
      <a
        href='#'
        className='btn btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px p-3'
        data-kt-menu='true'
      >

        {/* begin::Menu item */}
        <div className='menu-item'>
          <a className='menu-link px-3' onClick={openViewModal}>
            Megtekint
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item'>
          <a className='menu-link px-3' onClick={openCardsModal}>
            Kártyák
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item: TODO: ha nem exclusive */}
        { data?.dC_CardPackageTypeId !== CardPackageTypeEnum.EXCLUSIVE && (
        <div className='menu-item'>
          <a className='menu-link px-3' onClick={() => duplicateItem.mutateAsync()}>
            Másolás
          </a>
        </div>
        )}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {/* end::Menu item */}

      </div>
      {/* end::Menu */}
    </>
  )
}

export {ActionsCell}
