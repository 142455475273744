import {FC, useState, createContext, useContext} from 'react'
import {
  GeneralListViewContextProps,
  WithChildren,
  initialGeneralOrderListView,
} from '../../../../_metronic/helpers'
import { GroupDTO } from '@/app/models/group'

const ListViewContext = createContext<GeneralListViewContextProps<GroupDTO>>(initialGeneralOrderListView as GeneralListViewContextProps<GroupDTO>)

const ListViewProvider: FC<WithChildren> = ({children}) => {
  const [itemForUpdate, setItemForUpdate] = useState<GroupDTO>()
  const [itemForView, setItemForView] = useState<GroupDTO>()
  const [orderModal, setOrderModal] = useState<boolean>(false)
  
  return (
    <ListViewContext.Provider
      value={{
        orderModal,
        setOrderModal,
        itemForUpdate,
        setItemForUpdate,
        itemForView,
        setItemForView,
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
