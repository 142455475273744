import {FC, createContext, useContext, useEffect, useState} from 'react'
import {
  WithChildren,
} from '../../../../_metronic/helpers'
import { GroupDTO, GroupStatusEnum } from '@/app/models/group'
import { useQuery } from 'react-query'
import { getItemByIdRequest } from '../../groups/core/_requests'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../auth'

type ContextProps = {
    groupData: GroupDTO | undefined
    groupLoading: boolean
    groupError: any
    storedReshuffleDate: string | undefined
    setStoredReshuffleDate: (date: string | undefined) => void
    loginDate: string | undefined
    setLoginDate: (date: string | undefined) => void
}

const initialGroupContextValue: ContextProps = {
    groupData: undefined,
    groupLoading: false,
    groupError: null,
    storedReshuffleDate: undefined,
    setStoredReshuffleDate: () => {},
    loginDate: undefined,
    setLoginDate: () => {}
}

const GroupContext = createContext<ContextProps>(initialGroupContextValue)

const GroupProvider: FC<WithChildren> = ({children}) => {

  const navigate = useNavigate()
  const { trainee, saveTraineeAuth, setTrainee } = useAuth()

  const [storedReshuffleDate, setStoredReshuffleDate] = useState<string | undefined>(undefined)
  const [loginDate, setLoginDate] = useState<string | undefined>(undefined)

    let { groupId } = useParams()
    useEffect(() => {
      console.log('groupId', groupId );
    }, [groupId]);

    const {
        isLoading,
        data,
        error,
        isFetched
      } = useQuery(
        [`group`, groupId],
        () => {
          return getItemByIdRequest(groupId, false, true)
        },
        {
          cacheTime: 0,
          enabled: !!groupId,
          refetchOnWindowFocus: false,
          refetchInterval: 10000
        }
      )

  useEffect(() => {
    if (isFetched && !data) {
      navigate('/error/404')
    } else {
      if(data?.dC_GroupStatusId === GroupStatusEnum.CLOSED || (data?.dC_GroupStatusId === GroupStatusEnum.CREATED && !data?.isJoinEnableBeforeStart)) {
        navigate('/trainee/' + groupId + '/closed')
      }

      if(data?.groupId && data?.groupId !== trainee?.responseGroupId) {
        saveTraineeAuth(undefined)
        setTrainee(undefined)
        navigate('/trainee/'+groupId+'/join');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, data])
  
  return (
    <GroupContext.Provider
      value={{
        groupData: data,
        groupLoading: isLoading,
        groupError: error,
        storedReshuffleDate,
        setStoredReshuffleDate,
        loginDate,
        setLoginDate
      }}
    >
      {children}
    </GroupContext.Provider>
  )
}

const useGroup = () => useContext(GroupContext)

export {GroupProvider, useGroup}
