import { ID } from '@/_metronic/helpers';
import React from 'react';

// Define the shape of options prop
export interface Option {
  id: string | number;
  name: string;
}

// Define the structure of the FormikSelect component's props
interface FormikSelectProps {
  labelClass?: string;
  wrapperClass?: string;
  labelText?: string | null;
  selectProps?: { [key: string]: any }; // should contain name and other props for select element, including Formik handlers
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: any;
  name?: string;
  options: Option[];
  showEmptyOption?: boolean;
  placeholder?: string;
  formikTouched?: boolean | undefined;
  formikErrors?: string | undefined;
  required?: boolean;
}

const FormikSelect: React.FC<FormikSelectProps> = ({
  labelClass = 'fs-6 mb-2 required',
  wrapperClass,
  labelText = null,
  selectProps,
  onChange,
  name,
  value,
  options,
  formikTouched,
  formikErrors,
  showEmptyOption = true,
  placeholder = 'Kérjük válasszon',
}) => {
  return (
    <div className={wrapperClass}>
      {labelText && <label className={labelClass}>{labelText}</label>}
      
      <select name={name} onChange={onChange} value={value} className='form-select form-select-solid form-select-lg' {...selectProps}>
        {showEmptyOption && <option value=''>{placeholder}</option>}
        {options && options?.map(option => (
          <option key={option.id} value={option.id} >
            {option.name}
          </option>
        ))}
      </select>

      {formikTouched && formikErrors && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{formikErrors}</div>
        </div>
      )}
    </div>
  );
};

export default FormikSelect;