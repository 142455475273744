/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {ErrorData, errorHandler} from '../../../../_metronic/helpers'
import {AxiosError} from 'axios'
import {useAuth} from '../../auth/core/Auth'
import ReactCodeInput from 'react-code-input'
import {useNavigate} from 'react-router-dom'
import {studentLoginRequest} from '../core/_requests'

export function Join() {
  const navigate = useNavigate()

  const {saveTraineeAuth, setTrainee} = useAuth()

  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [code, setCode] = useState('')

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const pin = urlParams.get('pin')
    if (pin) {
      setCode(pin)
      handleSubmit(undefined, pin);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async (e: any, pin?: string) => {
    if(e) e.preventDefault()
    setLoading(true)
    try {
      const {token, studentId, groupId, errorResponse} = await studentLoginRequest(pin ?? code)

      const auth = {
        studentId,
        studentName: '',
        accessToken: token,
        responseGroupId: groupId,
      }

      saveTraineeAuth(auth)
      setTrainee(auth)
      navigate('/trainee/' + groupId + '/set-name')
    } catch (error) {
      const err = error as AxiosError<ErrorData>
      setStatus('Nem sikerült a belépés, próbálja újra!')
      if (err.response) {
        const errorText = errorHandler(err.response.status, err.response.data.errorResponse?.value?.errorInfo || '', err.response.data.errorResponse?.value?.errorDetails || '')
        setStatus(errorText)
        setTimeout(() => {
          setStatus('')
        }, 2500)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <form className='form w-100 text-center' onSubmit={handleSubmit} noValidate>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='fw-bolder mb-5 text-uppercase text-white' style={{fontSize: 32}}>
          Csatlakozás
        </h1>
      </div>
      {/* begin::Heading */}

      <p className='fs-2 mb-20'>Csatlakozás csoporthoz</p>

      {/* begin::Form group */}
      <div className='fv-row mb-20'>
        <label className='form-label fs-5 text-white d-block text-center'>Csoportkód</label>
        <ReactCodeInput
          inputStyle={{
            fontSize: 26,
            fontFamily: 'monospace',
            borderRadius: 6,
            border: '1px solid lightgrey',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px 0px',
            margin: 4,
            padding: '4px 4px 4px 8px',
            width: 36,
            height: 42,
            boxSizing: 'border-box',
            color: 'black',
            backgroundColor: 'white',
          }}
          value={code}
          onChange={(value) => setCode(value)}
          name='code'
          inputMode='numeric'
          type='text'
          fields={8}
        />
      </div>
      {/* end::Form group */}

      {status && (
        <div className='alert alert-danger mt-6'>
          <div className='alert-text font-weight-bold'>{status}</div>
        </div>
      )}

      {/* begin::Action */}
      <div className='d-grid mb-20'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-esl-red btn-shadow btn-secondary'
          disabled={code?.length < 4 || loading}
        >
          {!loading && <span className='indicator-label'>Tovább</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Kérem várjon...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
