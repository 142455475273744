import axios, { AxiosResponse } from 'axios';
import { ID } from '../../../../_metronic/helpers'
import { CardDTO, CardPackageCategoryDTO, CardPackageDTO, CardPackagesQueryResponse, FolderDTO } from '@/app/models/card'

const API_BASE_URL = process.env.REACT_APP_ESL_API_URL
const API_URL = API_BASE_URL + "CardPackage/"

const getItemsRequest = (query: string, userId?: ID): Promise<CardPackagesQueryResponse> => {
  return axios
    .get(`${API_URL}GetCardPackages?${query}${userId ? '&createdBy='+userId : ''}`)
    .then((d: AxiosResponse<CardPackagesQueryResponse>) => d.data)
}

const getItemByIdRequest = (id: ID): Promise<CardPackageDTO | undefined> => {
  return axios
    .get(`${API_URL}GetCardPackages?CardPackageIdList=${id}`)
    .then((response: AxiosResponse<CardPackagesQueryResponse>) => response.data.data?.[0])
}

const putItemRequest = (item: CardPackageDTO) => {
  const { cardPackageId } = item;
  
  delete item.folder;
  delete item.cardPackageCategory;
  delete item.dC_CardPackageAccessCategory;
  delete item.dC_CardPackageType;
  
  const url = cardPackageId ? `${API_URL}UpdateCardPackage` : `${API_URL}CreateCardPackage`;
  return axios.post<CardPackageDTO>(url, {data: [item]})
}

const deleteItemRequest = (cardPackageId: ID): Promise<void> => {
  return axios.post(`${API_URL}DeleteCardPackage`, { cardPackageId }).then(() => { })
}

const duplicateItemRequest = (cardPackageId: ID): Promise<void> => {
  return axios.post(`${API_BASE_URL}Card/DuplicateCardPackageList`, { cardPackageIdList: [cardPackageId] }).then(() => { })
}

const importCardsRequest = (cardIdList: ID[], cardPackageId: ID): Promise<void> => {
  return axios.post(`${API_BASE_URL}Card/ImportCardListOrCardPackageList`, { cardIdList, cardPackageId }).then(() => { })
}

const getAllFoldersSearchRequest = (search = '', userId: ID) => {
  let queryParams = new URLSearchParams();

  if (userId) queryParams.append('createdBy', userId.toString());
  if (search) queryParams.append('name', search);

  const url = `${API_BASE_URL}Folder/GetFolders${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;

  return axios.get(url)
    .then((response: AxiosResponse<{ data: FolderDTO[] }>) => 
      response.data.data.map(item => ({
        id: item.folderId,
        name: item.name,
      }))
    );
}

const getAllCardPackageCategoriesSearchRequest = (search = '') => {
  const response = axios.get(`${API_BASE_URL}CardPackageCategory/GetCardPackageCategory${search ? '?name=' + search : ''}`).then((response: AxiosResponse<any>) => { return response.data.data.map((item: CardPackageCategoryDTO) => { return { id: item.cardPackageCategoryId, name: item.name } })});
  return response;
}

const addFolderRequest = (folder: FolderDTO) => {
  return axios.post(`${API_BASE_URL}Folder/CreateFolder`, folder)
}

const getCardsByCardPackageIdRequest = (cardPackageId: ID) => {
  return axios.get(`${API_BASE_URL}Card/GetCard?CardPackageIdList=${cardPackageId ? cardPackageId : ''}`).then((response: AxiosResponse<any>) => response.data.data)
}

const deleteCardRequest = (cardId: ID): Promise<void> => {
  return axios.post(`${API_BASE_URL}Card/DeleteCard`, { cardId }).then(() => { })
}

const saveCardsForCardPackage = (items: CardDTO[], isUpdate = true) => {
  const url = isUpdate ? `${API_BASE_URL}Card/UpdateCard` : `${API_BASE_URL}Card/CreateCard`;
  return axios.post<CardDTO>(url, {data: items});
}

export { getItemsRequest, getItemByIdRequest, putItemRequest, deleteItemRequest, getAllFoldersSearchRequest, getAllCardPackageCategoriesSearchRequest, addFolderRequest, duplicateItemRequest, getCardsByCardPackageIdRequest, deleteCardRequest, saveCardsForCardPackage, importCardsRequest }
