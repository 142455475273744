import { ID } from "@/_metronic/helpers"
import { Response } from "@/_metronic/helpers"

// ENUMS

export enum CardTypeEnum {
    NORMAL = 1,
    FLASHCARD = 2,
}

export enum CardPackageTypeEnum {
    NORMAL = 1,
    EXCLUSIVE = 2,
}

export enum CardPackageAccessCategoryEnum {
    PUBLIC = 1,
    PRIVATE = 2,
}

// CARD PACKAGE CATEGORIES
export type CardPackageCategoryDTO = {
    cardPackageCategoryId: ID,
    name: string,
    cardPackageCount?: number,
}

export type CardPackageCategoriesQueryResponse = Response<Array<CardPackageCategoryDTO>>
export type CardPackageCategoryQueryResponse = Response<CardPackageCategoryDTO>

export const initialCardPackageCategoryData: CardPackageCategoryDTO = {
    cardPackageCategoryId: 0,
    name: ''
}

// CARD PACKAGES

export type CardPackageDTO = {
    cardPackageId: ID,
    name: string,
    cardPackageCategoryId: ID,
    cardPackageCategory?: CardPackageCategoryDTO,
    dC_CardPackageTypeId: ID,
    dC_CardPackageType?: DC_CardPackageType,
    dC_CardPackageAccessCategoryId?: ID,
    dC_CardPackageAccessCategory?: DC_CardPackageAccessCategory,
    description?: string,
    folderId?: ID,
    folder?: FolderDTO,
    cardCount?: number,
    smallUserDTO?: {
        userId: ID,
        userName: string,
    }
}

export type CardPackagesQueryResponse = Response<Array<CardPackageDTO>>
export type CardPackageQueryResponse = Response<CardPackageDTO>

export const initialCardPackageData: CardPackageDTO = {
    cardPackageId: 0,
    name: '',
    cardPackageCategoryId: '',
    dC_CardPackageTypeId: CardPackageTypeEnum.NORMAL,
    dC_CardPackageAccessCategoryId: CardPackageAccessCategoryEnum.PUBLIC,
    folderId: '',
    description: ''
}


// FOLDER

export type FolderDTO = {
    folderId: ID,
    name: string,
}

export type FoldersQueryResponse = Response<Array<FolderDTO>>
export type FolderQueryResponse = Response<FolderDTO>

export const initialFolderData: FolderDTO = {
    folderId: 0,
    name: ''
}

// CARDS

export type CardDTO = {
    cardId: ID,
    name: string,
    contentA?: string,
    contentB?: string,
    dC_CardTypeId: ID,
    dC_CardType?: DC_CardType,
    cardPackageId: ID,
    cardPackage?: CardPackageDTO,
}

export type CardsQueryResponse = Response<Array<CardDTO>>
export type CardQueryResponse = Response<CardDTO>

export const initialCardData: CardDTO = {
    cardId: 0,
    name: '',
    contentA: '',
    contentB: '',
    dC_CardTypeId: CardTypeEnum.NORMAL,
    cardPackageId: 0,
}

// DC TYPES

export type DC_CardPackageType = {
    dC_CardPackageTypeId: ID,
    name: string,
}

export type DC_CardType = {
    dC_CardTypeId: ID,
    name: string,
    createdBy?: ID,
    creationDate: string,
    isDeleted: boolean,
}

export type DC_CardPackageAccessCategory = {
    dC_CardPackageAccessCategoryId: ID,
    name: string,
}