import { ID } from "@/_metronic/helpers"
import { Response } from "@/_metronic/helpers"

// GROUP DTO
export type GroupDTO = {
    isDeleted?: boolean
    groupId?: number
    name?: string
    isJoinEnableBeforeStart?: boolean
    maxCardNumber?: number | null
    isEnableRemainingCards?: boolean
    cardSteppLimit?: number | null
    joinCode?: string
    joinLink?: string
    dC_GroupStatusId?: number
    dC_GroupStatus?: DC_GroupStatus
    cardPackageIdList?: number[]
    stopperStartTime?: string
    lastReshuffleDateTime?: string
    groupXStudentList?: GroupXStudentDTO[]
    cardCount?: number
    isStepBackEnable?: boolean
}

export type GroupsQueryResponse = Response<Array<GroupDTO>>
export type GroupQueryResponse = Response<GroupDTO>

export const initialGroupData: GroupDTO = {
    isDeleted: false,
    groupId: 0,
    name: '',
    isJoinEnableBeforeStart: false,
    maxCardNumber: null,
    isEnableRemainingCards: false,
    cardSteppLimit: null,
    joinCode: '',
    joinLink: '',
    dC_GroupStatusId: 2,
    cardPackageIdList: [],
    stopperStartTime: '',
    lastReshuffleDateTime: '',
    groupXStudentList: [],
    cardCount: 0,
    isStepBackEnable: false
}

// GroupXStudentDTO
export type GroupXStudentDTO = {
    isDeleted?: boolean
    groupXStudentId?: number
    groupId?: number
    group?: GroupDTO
    studentId?: number
    student?: StudentDTO
}

export type GroupXStudentQueryResponse = Response<Array<GroupXStudentDTO>>

// StudentDTO
export type StudentDTO = {
    isDeleted?: boolean
    studentId?: number
    studentName?: string
}

// DC

export type DC_GroupStatus = {
    isDeleted?: boolean
    createdBy?: number
    creationDate?: string
    dC_GroupStatusId?: number
    name?: string
}

// Enums
export enum GroupStatusEnum {
    CREATED = 1,
    STARTED = 2,
    CLOSED = 3
}