/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, FC } from 'react'
import { useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { registerRequest } from '../../core/_requests'
import { Link, useNavigate } from 'react-router-dom'
import { ErrorData, errorHandler } from '../../../../../_metronic/helpers'
import { sha256 } from 'crypto-hash';
import { AxiosError } from 'axios'
import { User as UserModel } from '@/app/models/user';

const registrationSchema = Yup.object().shape({
  aszfAccepted: Yup.bool().required('Kötelező mező'),
  firstName: Yup.string().required('Kötelező mező'),
  lastName: Yup.string().required('Kötelező mező'),
})

type Props = {
  data: UserModel
  setData: React.Dispatch<React.SetStateAction<UserModel>>
  setIndex: React.Dispatch<React.SetStateAction<number>>
}

const Registration2: FC<Props> = ({ data, setData, setIndex }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const navigate = useNavigate();
  const formik = useFormik<UserModel>({
    initialValues: data,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      try {
     

        const sha256Pw = await sha256(values.password as string);
        const { data: auth } = await registerRequest(
          values.emailAddress as string,
          values.firstName as string,
          values.lastName as string,
          sha256Pw
        )

        setSuccess(true);
        setTimeout(() => {
          setSuccess(false)
          resetForm()
          navigate('/auth/successful-registration')
        }, 2500)

      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Nem sikerült a módosítás, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(err.response.status, err.response.data.errorResponse?.value?.errorInfo || '', err.response.data.errorResponse?.value?.errorDetails || '');
          setStatus(errorText)
          setTimeout(() => {
            setStatus("")
          }, 2500)
        }
      } finally {
        setSubmitting(true)
        setLoading(false)
      }

    },
  })

  return (
    <form
      className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework`}
      noValidate
      id='kt_login_signup_form-2'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Regisztráció</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}


      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group lastName */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Vezetéknév</label>
        <input
          placeholder='Vezetéknév'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastName')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.lastName && formik.errors.lastName,
            },
            {
              'is-valid': formik.touched.lastName && !formik.errors.lastName,
            }
          )}
        />
      </div>
      {/* end::Form group */}
      {/* begin::Form group lastName */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Keresztnév</label>
        <input
          placeholder='Keresztnév'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstName')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.firstName && formik.errors.firstName,
            },
            {
              'is-valid': formik.touched.firstName && !formik.errors.firstName,
            }
          )}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-check form-check-inline required' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            checked={formik.values.aszfAccepted}
            {...formik.getFieldProps('aszfAccepted')}
          />
          <span>
            Elfogadom az{' '}
            <Link
              to='/dokumentumok/aszf'
              target='_blank'
              className='ms-1 link-primary'
            >
              Általános szerződési feltételeket
            </Link>
            {' '}és az{' '}<Link
              to='/dokumentumok/adatvedelmi-iranyelvek'
              target='_blank'
              className='ms-1 link-primary'
            >
              Adatvédelmi nyilatkozatot
            </Link>.
          </span>
        </label>
        {formik.touched.aszfAccepted && formik.errors.aszfAccepted && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.aszfAccepted}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {formik.status && (
        <div className='alert alert-danger mt-4'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {success && (
        <div className='mb-10 bg-light-success p-4 rounded mt-4'>
          <div className='text-success'>Sikeres regisztráció!</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='text-center mt-8'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.aszfAccepted}
        >
          {!loading && <span className='indicator-label'>Regisztráció</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Kérem várjon...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <button
          type='button'
          className='btn btn-sm btn-light-primary w-100 mb-5'
          onClick={() => setIndex(0)}
        >
          Vissza
        </button>
        <div>
          Már van fiókja? {' '} <Link to='/auth/login' className='link-primary fw-bolder'>Bejelentkezés</Link>
        </div>
      </div>
      {/* end::Form group */}
    </form>
  )
}
export default Registration2;
