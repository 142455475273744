/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, FC } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { PasswordMeterComponent } from '../../../../../_metronic/assets/ts/components'
import EyeButton from '../eye-button/EyeButton'
import { User as UserModel } from '@/app/models/user';
import { Link } from 'react-router-dom'

const registrationSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email('Nem megfelelő formátum')
    .min(3, 'Minimum 3 karakter a követelmény')
    .max(50, 'Maximum 50 karakter az engedélyezett')
    .required('Kötelező mező'),
  password: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .max(50, 'Maximum 50 karakter az engedélyezett')
    .required('Kötelező mező'),
  confirmPassword: Yup.string()
    .required('Kötelező mező')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Nem egyezik a két mező értéke"),
    }),
})

type Props = {
  data: UserModel
  setData: React.Dispatch<React.SetStateAction<UserModel>>
  setIndex: React.Dispatch<React.SetStateAction<number>>
}

const Registration1: FC<Props> = ({ data, setData, setIndex }) => {
  const [loading, setLoading] = useState(false)
  const [passwordType, setPasswordType] = useState("password");


  const formik = useFormik<UserModel>({
    initialValues: data as UserModel,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        setIndex(1)
      } catch (error) {
        setStatus('Valami nem stimmel, kérem próbálja meg később!')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form-1'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-8'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Regisztráció</h1>
        {/* end::Title */}

        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>
      {/* end::Heading */}


      {/* 
      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div> */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Email */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6 required'>E-mail</label>
        <input
          placeholder='E-mail'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('emailAddress')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.emailAddress && formik.errors.emailAddress },
            {
              'is-valid': formik.touched.emailAddress && !formik.errors.emailAddress,
            }
          )}
        />
        {formik.touched.emailAddress && formik.errors.emailAddress && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.emailAddress}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-4' data-kt-password-meter='true'>
        <div className='mb-1 position-relative'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Jelszó</label>
          <div className='mb-3'>
            <div className='position-relative'>
              <input
                type={passwordType}
                placeholder='Jelszó'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <EyeButton passwordType={passwordType} setPasswordType={setPasswordType} password={formik.values.password}></EyeButton>
            </div>

            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        {/* <div className='text-muted'>
        Használjon 8 vagy több karaktert betűk, számok és szimbólumok keverékével.
        </div> */}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Jelszó újra</label>
        <input
          type='password'
          placeholder='Jelszó újra'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {formik.status && (
        <div className='alert alert-danger mt-4'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Tovább</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Kérem várjon..{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div>
          Már van fiókja? {' '} <Link to='/auth/login' className='link-primary fw-bolder'>Bejelentkezés</Link>
        </div>
      </div>
      {/* end::Form group */}
    </form>
  )
}

export default Registration1;
