import axios from 'axios'
import { StepBackwardStudentXCardResponseDTO, StepForwardStudentXCardResponseDTO, StudentRequestDTO, StudentResponseDTO } from './_models'
import { ID } from '@/_metronic/helpers'

const API_URL = process.env.REACT_APP_ESL_API_URL

const cardStepForwardRequest = (studentId: number): Promise<StepForwardStudentXCardResponseDTO> => {
    return axios.post(`${API_URL}StudentXCard/StepForward`, { studentId }).then((response) => response.data)
}

const cardStepBackwardRequest = (studentId: number): Promise<StepBackwardStudentXCardResponseDTO> => {
    return axios.post(`${API_URL}StudentXCard/StepBack`, { studentId }).then((response) => response.data)
}

const leaveGroupRequest = (studentId: number): Promise<void> => {
    return axios.post(`${API_URL}GroupXStudent/LeaveGroupXStudent`, { studentId }).then(() => { })
}

const updateStudentRequest = (item: StudentRequestDTO): Promise<void> => {
    return axios.post(`${API_URL}Student/UpdateStudent`, { data: [item] }).then(() => { })
}

const studentLoginRequest = (joinCode: ID): Promise<StudentResponseDTO> => {
    return axios.post(`${API_URL}Student/StudentLogin`, { joinCode }).then((response) => response.data)
}

export { cardStepForwardRequest, cardStepBackwardRequest, leaveGroupRequest, updateStudentRequest, studentLoginRequest }