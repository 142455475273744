import { useQuery } from 'react-query'
import { isNotEmpty} from '../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { ImportCardsModalForm } from './content/ImportCardsModalForm'
import { getItemByIdRequest } from '../core/_requests'

type Props = {
  onClose: () => void
}

const ImportCardsModalFormWrapper = ({onClose}: Props) => {
  const { itemForView, setItemForView } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemForView) && itemForView?.cardPackageId !== 0

  const {
    isLoading,
    data
  } = useQuery(
    `my-card-packages-${itemForView?.cardPackageId}`,
    () => {
      return getItemByIdRequest(itemForView?.cardPackageId)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err) => {
        setItemForView?.(undefined)
        console.error(err)
      },
    }
  )

  return <ImportCardsModalForm onClose={onClose} isLoading={isLoading} Data={data}/>
}

export { ImportCardsModalFormWrapper }
