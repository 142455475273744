import {FC} from 'react'
import clsx from 'clsx'
import {useListView} from '../../core/ListViewProvider'
import {ListLoading} from '@/app/common/table/components/loading/ListLoading'
import {
  CardPackageDTO
} from '@/app/models/card'
import { useAuth } from '@/app/modules/auth'

type Props = {
  isLoading: boolean
  Data: CardPackageDTO | undefined
}

const EditModalForm: FC<Props> = ({Data, isLoading}) => {
  const {setItemForUpdate} = useListView()
  const { auth } = useAuth()

  const cancel = () => {
    setItemForUpdate?.(undefined)
  }

  return (
    <>
      <div className='form'>
        {/* begin::Scroll */}
        <div className='d-flex flex-column me-n7 pe-7'>
          <div className='fv-row mb-8'>
            <div className='row g-4'>
              <div className='col-12'>
                <label className='fs-6 mb-2'>Csomag neve</label>
                <input
                  placeholder='Csomag neve'
                  type='text'
                  value={Data?.name}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0'
                  )}
                  autoComplete='off'
                  readOnly
                />
              </div>

              <div className='col-12'>
                <label className='fs-6 mb-2'>Kategória</label>
                <input
                  placeholder='Kategória'
                  type='text'
                  value={Data?.cardPackageCategory?.name}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0'
                  )}
                  autoComplete='off'
                  readOnly
                />
              </div>

              <div className='col-12'>
                <label className='fs-6 mb-2'>Csomag típusa</label>
                <input
                  placeholder='Csomag típusa'
                  type='text'
                  value={Data?.dC_CardPackageType?.name}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0'
                  )}
                  autoComplete='off'
                  readOnly
                />
              </div>

              <div className='col-12'>
                <label className='fs-6 mb-2'>Elérhetőség</label>
                <input
                  placeholder='Elérhetőség'
                  type='text'
                  value={Data?.dC_CardPackageAccessCategory?.name}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0'
                  )}
                  autoComplete='off'
                  readOnly
                />
              </div>

              <div className='col-12'>
                <label className='fs-6 mb-2'>Rövid Leírás</label>
                <input
                  placeholder='Rövid Leírás'
                  type='text'
                  value={Data?.description}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0'
                  )}
                  autoComplete='off'
                  readOnly
                />
              </div>

              { Data?.smallUserDTO?.userId === auth?.userId && (
              <div className='col-12'>
                <label className='fs-6 mb-2'>Mappa</label>
                <input
                  placeholder='Mappa'
                  type='text'
                  value={Data?.folder?.name}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0'
                  )}
                  autoComplete='off'
                  readOnly
                />
              </div>
              )}
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-card-packages-modal-action='cancel'
            disabled={isLoading}
          >
            Bezár
          </button>
        </div>
        {/* end::Actions */}
      </div>
      {(isLoading) && <ListLoading />}
    </>
  )
}

export {EditModalForm}
