import React, {createContext, useContext, useState} from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_ESL_API_URL
interface IDCTablesContext {
  dcTables: Record<string, any>
  getDcList: (requestedTables: string[]) => Promise<DCTables | undefined>
}

export type DCList = {
  id: number
  name: string
}

const DCTablesContext = createContext<IDCTablesContext | undefined>(undefined)

interface DCTablesProviderProps {
  children: React.ReactNode
}

export type DCTables = {
  [key: string]: DCList[]
}

export const DCTablesProvider: React.FC<DCTablesProviderProps> = ({children}) => {
  const [dcTables, setDcTables] = useState<Record<string, any>>({})

  async function fetchDcTableData(tables: string[]): Promise<DCTables> {
    const upperCaseTables = tables.map((table) => {
      return table.charAt(0).toUpperCase() + table.slice(1)
    })
    const query = `?TableNameList=${upperCaseTables.join('&TableNameList=')}`
    const response = await axios.get(`${API_URL}DC/GetDC_TableList${query}`)

    const returnData: DCTables = tables.reduce((acc, tableName) => {
      acc[tableName] = response.data[tableName].map((item: any) => {
        switch(tableName) {
          case 'dC_Country':
            return {id: item.dC_CountryId, name: item.name}
          case 'dC_CardPackageAccessCategory':
            return {id: item.dC_CardPackageAccessCategoryId, name: item.name}
          case 'dC_CardPackageType':
            return {id: item.dC_CardPackageTypeId, name: item.name}
          case 'dC_CardType':
            return {id: item.dC_CardTypeId, name: item.name}
          case 'dC_GroupStatus':
            return {id: item.dC_GroupStatusId, name: item.name}
          default:
            return item
        }
      })
      return acc
    }, {} as DCTables) // Add index signature to allow indexing with a string

    return returnData;
  }

  const getDcList = async (requestedTables: string[]): Promise<DCTables | undefined> => {
    try {
      const tablesToFetch = requestedTables.filter((tableName) => !dcTables[tableName])
      const response = tablesToFetch.length > 0 ? await fetchDcTableData(tablesToFetch) : {}

      const newStoredDcTables = {...dcTables, ...response};
      setDcTables((prev) => ({...prev, ...response}))

      const returnData: DCTables = {}
      requestedTables.forEach((tableName) => {
        returnData[tableName] = newStoredDcTables[tableName]
      })

      return returnData
    } catch (error) {
      console.error('Error loading DC tables:', error)
    }
  }

  return (
    <DCTablesContext.Provider value={{dcTables, getDcList}}>{children}</DCTablesContext.Provider>
  )
}

export const useDCTables = () => {
  const context = useContext(DCTablesContext)
  if (!context) {
    throw new Error('useDCTables must be used within a DCTablesProvider')
  }
  return context
}
