import { ID, Response } from '../../_metronic/helpers'
export type User = {
  userId?: ID,
  userName?: string,
  firstName?: string,
  lastName?: string,
  genderId?: ID,
  emailAddress?: string,
  phoneNumber?: string,
  postalCode?: ID,
  dateOfBirth?: string,
  aszfAccepted?: boolean,
  marketingStatementAccepted?: boolean,
  isAdult?: boolean,
  roleId?: ID,
  role?: RoleDTO
  isActive?: boolean | string,
  isDeleted?: boolean,
  password?: string,
  confirmPassword?: string,
  token?: string,
  dC_CountryId?: number | string | null,
  creationDate?: string,
  lastLoginAt?: string,
}

export type RoleDTO = {
  roleId?: ID,
  roleName?: string,
  roleXRight?: RoleXRightDTO[]
}

export type RoleXRightDTO = {
  right?: string[],
  rightId?: ID,
  roleId?: ID,
  userXRightId?: ID,
}

export type UsersQueryResponse = Response<Array<User>>
export type UserQueryResponse = Response<User>

export const initialUser: User = {
  userName: '',
  firstName: '',
  lastName: '',
  genderId: null,
  emailAddress: '',
  phoneNumber: '',
  postalCode: null,
  dateOfBirth: '',
  aszfAccepted: false,
  marketingStatementAccepted: false,
  isAdult: false,
  roleId: '',
  isActive: false,
  isDeleted: false,
  password: '',
  confirmPassword: '',
  dC_CountryId: '',
  creationDate: '',
  lastLoginAt: '',
}

export enum UserRoleEnum {
  ADMIN = 1,
  FREE = 2,
  PREMIUM = 3,
  ULTIMATE = 4,
  CONTENT_MANAGER = 5,
}

export enum UserGenderEnum {
  MALE = 1,
  FEMALE = 2,
  OTHER = 3,
}

export const userRoleList = [
  { id: UserRoleEnum.ADMIN, name: 'Adminisztrátor' },
  { id: UserRoleEnum.FREE, name: 'Free' },
  { id: UserRoleEnum.PREMIUM, name: 'Premium' },
  { id: UserRoleEnum.ULTIMATE, name: 'Ultimate' },
  { id: UserRoleEnum.CONTENT_MANAGER, name: 'Content Manager' },
]

export enum RightEnum {
  CategoryEditor = 1,
  UserEditing = 2,
  CardCharacterLimitUnlimited = 3,
  CardCharacterLimit100 = 4,
  CardCharacterLimit200 = 5,
  CardIteration = 6,
  CardFixedRowLength = 7,
  CardImages = 8,
  CardPrivatePackages = 9,
  CopyFreePackages = 10,
  CopyPremiumPackages = 11,
  CardFlashcard = 12,
  ExclusivePackagesGroupUnlimited = 13,
  ExclusivePackagesGroupMax10 = 14,
  ExclusivePackagesGroupMax30 = 15,
  ExclusivePackageCreation = 16,
}