import React, { useEffect, useState } from 'react';

type Props = {
    callQueuedTime: string;
};

const Timer = ({ callQueuedTime }: Props) => {
  const [time, setTime] = useState(0);
  const [formattedTime, setFormattedTime] = useState('00:00');
  useEffect(() => {
    const queuedTime = new Date(callQueuedTime).getTime();
    const intervalId = setInterval(function () {
      setTime(new Date().getTime() - queuedTime);
    }, 1000);
    return ()=>{
      clearInterval(intervalId);
    }
  }, [callQueuedTime]);

  useEffect(() => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
  
    let formattedTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    if (hours > 0) {
      formattedTime = `${hours}:${formattedTime}`;
    }
  
    setFormattedTime(formattedTime);
  }, [time]);
  
  return <span>{callQueuedTime ? formattedTime : '--:--'}</span>;
};

export default Timer;