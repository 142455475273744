import { FC, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ErrorData, QUERIES, errorHandler } from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import { useListView } from '../../core/ListViewProvider'
import { ListLoading } from '@/app/common/table/components/loading/ListLoading'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { AxiosError } from 'axios'
import { deleteItemRequest, putItemRequest } from '../../core/_requests'
import { useMutation, useQueryClient } from 'react-query'
import Swal from 'sweetalert2'
import { CardPackageCategoryDTO, initialCardPackageCategoryData } from '@/app/models/card'

type Props = {
  isUserLoading: boolean
  Data: CardPackageCategoryDTO | undefined
}

const EditModalForm: FC<Props> = ({ Data, isUserLoading }) => {
  const [success, setSuccess] = useState<boolean>(false);

  const DataForEdit = Data ?? {...initialCardPackageCategoryData};

  useEffect(() => {
    if (Data) {
      formik.setValues(Data)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data]);

  const isNew = !Data?.cardPackageCategoryId;

  const { setItemForUpdate } = useListView()
  const { refetch, query } = useQueryResponse()
  const queryClient = useQueryClient()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemForUpdate?.(undefined)
  }

  const formik = useFormik({
    initialValues: DataForEdit,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, 'Minimum 2 karakter.')
        .max(50, 'Maximum 50 karakter.')
        .required('Kötelező mező')
    }),
    onSubmit: async (values, { setStatus, setSubmitting,resetForm }) => {
      setSubmitting(true)
      try {
        const formData = Object.assign(DataForEdit, formik.values)

        const { data } = await putItemRequest(formData)

        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          cancel(true)
          resetForm()
        }, 2500)

      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Nem sikerült a mentés, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(err.response.status, err.response.data.errorResponse?.value?.errorInfo || '', err.response.data.errorResponse?.value?.errorDetails || '');
          setStatus(errorText)
          setTimeout(() => {
            setStatus("")
          }, 2500)
        }
      } finally {
        setSubmitting(true)
      }
    },
  })

  const handleDeleteClick = async () => {
    const result = await Swal.fire({
      title: 'Biztos, hogy törölni szeretnéd a csomag kategóriát?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Törlés',
      cancelButtonText: 'Mégse',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary',
      },
    })

    if (result.isConfirmed) {
      await deleteItem.mutateAsync()
    }
  }

  const deleteItem = useMutation(() => deleteItemRequest(Data?.cardPackageCategoryId), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CATEGORIES_LIST}-${query}`]);
      cancel(true);
    },
  })

  return (
    <>
      <form id='kt_modal_add_User_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column me-n7 pe-7'
        >

          <div className='fv-row mb-8'>
            <div className='row g-4'>
              <div className='col-12'>
                <label className='fs-6 mb-2 required'>Kategória neve</label>
                {/* end::Label */}
                {/* begin::Input */}
                <input
                  placeholder='Kategória neve'
                  {...formik.getFieldProps('name')}
                  type='text'
                  name="name"
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.name && formik.errors.name },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {formik.status && (
          <div className='alert alert-danger mt-6'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        {success && (
          <div className='mb-10 bg-light-success p-4 rounded mt-6'>
            <div className='text-success'>Sikeres mentés!</div>
          </div>
        )}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          {!isNew && (
          <button
              type='button'
              onClick={handleDeleteClick}
              className='btn btn-danger me-3'
              disabled={formik.isSubmitting || isUserLoading}
            >
              Törlés
          </button>
          )}

          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Mégse
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.touched}
          >
            <span className='indicator-label'>Mentés</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Kérem várjon...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <ListLoading />}
    </>
  )
}

export { EditModalForm }
