/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo, createContext} from 'react'
import {useQuery} from 'react-query'
import {
  ID,
  initialQueryState,
  PaginationState,
  QUERIES,
  QueryResponseContextProps,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../_metronic/helpers'
import { getAllExclusivePackagesRequest, getItemsRequest} from './_requests'
import {useQueryRequest} from './QueryRequestProvider'
import { GroupDTO } from '@/app/models/group'
import { getAllFoldersSearchRequest } from '../../my-card-packages/core/_requests'
import { useAuth } from '../../auth'

type CardPackageQueryResponseContextProps<T> = QueryResponseContextProps<T> & {
  foldersListRefetch: () => void
  foldersListResponse: any
  isFetched: boolean,
  exclusivePackagesIsFetching: boolean,
  exclusivePackagesResponse: any,
  exclusivePackagesRefetch: () => void
}

const initialQueryResponse = { refetch: () => { }, isLoading: false, isFetched: false, query: '', foldersListRefetch: () => { }, foldersListResponse: [], exclusivePackagesIsFetching: false, exclusivePackagesResponse: [], exclusivePackagesRefetch: () => { } }

const QueryResponseContext = createContext<CardPackageQueryResponseContextProps<GroupDTO>>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const {auth} = useAuth()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
    isFetched,
  } = useQuery(
    `${QUERIES.GROUP_LIST}-${query}`,
    () => {
      return getItemsRequest(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false, refetchInterval: 10000}
  )

  const {
    isFetching: foldersListIsFetching,
    refetch: foldersListRefetch,
    data: foldersListResponse,
  } = useQuery(
    `${QUERIES.FOLDERS_LIST}`,
    () => {
      const userId = auth?.userId
      return getAllFoldersSearchRequest('', userId)
    },
    {keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: exclusivePackagesIsFetching,
    refetch: exclusivePackagesRefetch,
    data: exclusivePackagesResponse,
  } = useQuery(
    `${QUERIES.EXCLUSIVE_PACKAGES_LIST}`,
    () => {
      return getAllExclusivePackagesRequest().then((response) => response.data);
    },
    {keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, isFetched, response, query, foldersListRefetch, foldersListResponse, exclusivePackagesIsFetching, exclusivePackagesResponse, exclusivePackagesRefetch}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
