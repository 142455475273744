import axios, { AxiosResponse } from 'axios';
import { ID } from '../../../../_metronic/helpers'
import { CardPackagesQueryResponse, FolderDTO } from '@/app/models/card'
import { GroupDTO, GroupXStudentQueryResponse, GroupsQueryResponse } from '@/app/models/group';

const API_BASE_URL = process.env.REACT_APP_ESL_API_URL
const API_URL = API_BASE_URL + "Group/"

const getItemsRequest = (query: string): Promise<GroupsQueryResponse> => {
  return axios
    .get(`${API_URL}GetGroup?${query}`)
    .then((d: AxiosResponse<GroupsQueryResponse>) => d.data)
}

const getItemByIdRequest = (id: ID, needCardPackageIdList = true, needGroupXStudent = false): Promise<GroupDTO | undefined> => {
  return axios
    .get(`${API_URL}GetGroup?GroupIdList=${id}${needCardPackageIdList ? '&needCardPackageIdList=true' : ''}${needGroupXStudent ? '&needGroupXStudent=true' : ''}`)
    .then((response: AxiosResponse<GroupsQueryResponse>) => response.data.data?.[0])
}

const putItemRequest = (item: GroupDTO) => {
  const { groupId } = item;
  
  const url = groupId ? `${API_URL}UpdateGroup` : `${API_URL}CreateGroup`;
  return axios.post<GroupDTO>(url, {data: [item]})
}

const deleteItemRequest = (groupId: ID): Promise<void> => {
  return axios.post(`${API_URL}DeleteGroup`, { groupId }).then(() => { })
}

const reshuffleItemRequest = (groupId: ID): Promise<void> => {
  return axios.post(`${API_URL}Reshuffle`, { groupId }).then(() => { })
}

const startGroupRequest = (groupId: ID): Promise<void> => {
  return axios.post(`${API_URL}StartGroup`, { groupId }).then(() => { })
}

const stopGroupRequest = (groupId: ID): Promise<void> => {
  return axios.post(`${API_URL}StopGroup`, { groupId }).then(() => { })
}

const getGroupXStudentRequest = (groupId: ID): Promise<GroupXStudentQueryResponse> => {
  return axios.get(`${API_BASE_URL}GroupXStudent/GetGroupXStudent?GroupIdList=${groupId}`).then((response: AxiosResponse<GroupXStudentQueryResponse>) => response.data);
}

const addFolderRequest = (folder: FolderDTO) => {
  return axios.post(`${API_BASE_URL}Folder/CreateFolder`, folder)
}

const getAllExclusivePackagesRequest = () => {
  return axios
    .get(`${API_BASE_URL}CardPackage/GetCardPackages?dC_CardPackageTypeIdList=2`)
    .then((d: AxiosResponse<CardPackagesQueryResponse>) => d.data)
}

export { getItemsRequest, getItemByIdRequest, putItemRequest, deleteItemRequest, addFolderRequest, getGroupXStudentRequest, reshuffleItemRequest, startGroupRequest, stopGroupRequest, getAllExclusivePackagesRequest }
