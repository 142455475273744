/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useMemo, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {QUERIES} from '../../../../_metronic/helpers'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {reshuffleItemRequest, startGroupRequest, stopGroupRequest} from '../core/_requests'
import { GroupDTO, GroupStatusEnum } from '@/app/models/group'
import { KTCard } from '@/_metronic/helpers'
import Timer from '@/app/common/timer'
import { ActionsCell } from './ActionsCell'
import { useListView } from '../core/ListViewProvider'

type Props = {
  group: GroupDTO
  className?: string
}

const PAGE_URL = document.location.origin

const GroupItem: FC<Props> = ({group, className}) => {
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  const {setItemForUpdate} = useListView()

  const [reshuffleStatus, setReshuffleStatus] = useState('')

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text)
  }

  const reshuffleItem = useMutation(() => reshuffleItemRequest(group?.groupId), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.GROUP_LIST}-${query}`])

      setReshuffleStatus('Sikeres!');
        setTimeout(() => {
            setReshuffleStatus('')
        }, 2500)
    },
  })

  const startGroup = useMutation(() => startGroupRequest(group?.groupId), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.GROUP_LIST}-${query}`])
    },
  })

  const stopGroup = useMutation(() => stopGroupRequest(group?.groupId), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.GROUP_LIST}-${query}`])
    },
  })

  const groupLink = useMemo(() => {
    return `${PAGE_URL}/trainee/join?pin=${group?.joinCode}`
  }, [group])

  return (
    <>
      <KTCard className={`group-item p-4 ${className} ${group?.dC_GroupStatusId === GroupStatusEnum.STARTED ? 'active' : ''}`}>
        <div className="row g-3">
            <div className="col-12 col-lg">
                <h3 className="h2 group-item__title">{group?.name}</h3>
                <div>Csatlakozott nézők: {group?.groupXStudentList?.length || '--'}</div>
                <div>Kártyák száma: {group?.cardCount || 0}</div>
            </div>
            <div className="col-12 col-lg-6">
                <div className="group-item__card group-item__card--connect mb-3">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6">
                            <div className="row">
                                <div className="col-auto">
                                    Csatlakozás:
                                </div>
                                <div className="col-auto fw-bold">
                                    {group?.joinCode}
                                </div>
                                <div className="col">
                                    <button onClick={() => copyText(group?.joinCode || '')} type='button' className="btn btn-icon btn-sm w-auto h-auto p-1">
                                        <i className="la la-copy fs-4"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="row">
                                <div className="col fw-bold">
                                    {groupLink}
                                </div>
                                <div className="col-auto">
                                    <button onClick={() => copyText(groupLink || '')} type='button' className="btn btn-icon btn-sm w-auto h-auto p-1">
                                        <i className="la la-copy fs-4"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row align-items-stretch">
                    <div className="col">
                        <div className="group-item__card group-item__card--counter">
                            { group?.dC_GroupStatusId === GroupStatusEnum.STARTED && (
                            <Timer callQueuedTime={group?.stopperStartTime || ''} />
                            )}
                            { group?.dC_GroupStatusId !== GroupStatusEnum.STARTED && (
                                <span>--:--</span>
                            )}
                        </div>
                    </div>
                    <div className="col">
                        <button disabled={group?.dC_GroupStatusId !== GroupStatusEnum.STARTED} onClick={() => reshuffleItem.mutateAsync()} type="button" className="group-item__card w-100 group-item__card--reshuffle-button d-flex align-items-center">
                            <span className='me-4'>{reshuffleStatus || 'Újraosztás'}</span>
                            <i className="la la-sync-alt fs-1"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg align-self-center">
                <div className="d-flex flex-column">
                    <div className='mb-4'>
                        <button onClick={() => setItemForUpdate?.(group)} type='button' disabled={group?.dC_GroupStatusId === GroupStatusEnum.STARTED} className={`btn btn-primary w-100`}>Beállítások</button>
                    </div>
                    <div>
                        { group?.dC_GroupStatusId === GroupStatusEnum.STARTED && (
                            <button onClick={() => stopGroup.mutateAsync()} type='button' className={`btn btn-danger w-100`}>Leállítás</button>
                        )}
                        { (group?.dC_GroupStatusId === GroupStatusEnum.CREATED || group?.dC_GroupStatusId === GroupStatusEnum.CLOSED) && (
                            <button onClick={() => startGroup.mutateAsync()} type='button' className={`btn btn-success w-100`}>Indítás</button>
                        )}
                    </div>
                </div>
            </div>
            <div className="col-auto align-self-center">
                <ActionsCell data={group} />
            </div>
        </div>
      </KTCard>
    </>
  )
}

export {GroupItem}
