import {ErrorData, KTSVG, errorHandler} from '@/_metronic/helpers'
import {useFormik} from 'formik'
import {AxiosError} from 'axios'
import * as Yup from 'yup'
import {useState} from 'react'
import {addFolderRequest} from '../core/_requests'
import clsx from 'clsx'

type Props = {
  onClose: () => void
  onSave: () => void
}

const AddFolderModal = ({onClose, onSave}: Props) => {
  const [success, setSuccess] = useState<boolean>(false)
  const formik = useFormik({
    initialValues: {
      name: '',
      folderId: 0,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, 'Minimum 2 karakter.')
        .max(50, 'Maximum 50 karakter.')
        .required('Kötelező mező'),
    }),
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        const formData = Object.assign({}, formik.values)

        const {data} = await addFolderRequest(formData)

        setSuccess(true)
        onSave();
        setTimeout(() => {
          setSuccess(false)
          onClose()
          resetForm()
        }, 2500)
      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Nem sikerült a mentés, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(err.response.status, err.response.data.errorResponse?.value?.errorInfo || '', err.response.data.errorResponse?.value?.errorDetails || '')
          setStatus(errorText)
          setTimeout(() => {
            setStatus('')
          }, 2500)
        }
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_folder'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>Mappa létrehozása</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-add-folder-modal-action='close'
                onClick={onClose}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <form
                id='kt_modal_addfolder_form'
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div>
                  <label className='fs-6 mb-2 required'>Mappa neve</label>
                  {/* end::Label */}
                  {/* begin::Input */}
                  <input
                    placeholder='Mappa neve'
                    {...formik.getFieldProps('name')}
                    type='text'
                    name='name'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.name && formik.errors.name},
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>

                {formik.status && (
                  <div className='alert alert-danger mt-6'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                )}
                {success && (
                  <div className='mb-10 bg-light-success p-4 rounded mt-6'>
                    <div className='text-success'>Sikeres mentés!</div>
                  </div>
                )}
                
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => onClose()}
                    className='btn btn-light me-3'
                    data-kt-my-card-packages-modal-action='cancel'
                    disabled={formik.isSubmitting}
                  >
                    Mégse
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-my-card-packages-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.touched}
                  >
                    <span className='indicator-label'>Mentés</span>
                    {formik.isSubmitting && (
                      <span className='indicator-progress'>
                        Kérem várjon...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {AddFolderModal}
