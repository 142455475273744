
import { CSSProperties, useEffect, useState } from 'react';
import { animated, useSpringRef, AnimatedProps, useTransition } from 'react-spring'
import Registration1 from './RegistrationPages/RegistrationForm1'
import Registration2 from './RegistrationPages/RegistrationForm2'
import styles from './RegistrationPages/styles.module.css'
import { initialUser, User as UserModel } from '@/app/models/user';

export const RegistrationWrapper: React.FC = () => {
    const [index, set] = useState(0)
    const [prevIdx, setPrevIdx] = useState(-1)
    const [data, setData] = useState<UserModel>(initialUser)

    const transRef = useSpringRef()

    const transitions = useTransition(index, {
        ref: transRef,
        keys: null,
        from: () => ({ opacity: 0, transform: `translate(${prevIdx < index ? 100 : -100}%,0,0)` }),
        enter: () => ({ opacity: 1, transform: `translate3d(0%,0,0)` }),
        leave: () => ({ opacity: 0, transform: `translate3d(${prevIdx < index ? -50 : 50}%,0,0)` }),
        onRest: () => {
            setPrevIdx(index)
        }
    })
    useEffect(() => {
        transRef.start()
    }, [index])

    const pages: ((props: AnimatedProps<{ style: CSSProperties }>) => React.ReactElement)[] = [
        ({ style }) => <animated.div style={{ ...style }}><Registration1 data={data} setData={setData} setIndex={set} /></animated.div>,
        ({ style }) => <animated.div style={{ ...style }}><Registration2 data={data} setData={setData} setIndex={set} /></animated.div>,
    ]

    return (
                <div id="registration_form">
                    <div className={`flex animation-fill ${styles.container}`} style={{minHeight: 600}}>
                    {transitions((style, i) => {
                        const Page = pages[i]
                        return <Page style={style} />
                    })}
                    </div>
                 
                </div>
    );

}