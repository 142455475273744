import axios, { AxiosResponse } from 'axios';
import { ID } from '../../../../_metronic/helpers'
import { CardPackageCategoryDTO, CardPackageCategoriesQueryResponse } from '@/app/models/card'

const API_URL = process.env.REACT_APP_ESL_API_URL + "CardPackageCategory/"

const getItemsRequest = (query: string): Promise<CardPackageCategoriesQueryResponse> => {
  return axios
    .get(`${API_URL}GetCardPackageCategory?${query}`)
    .then((d: AxiosResponse<CardPackageCategoriesQueryResponse>) => d.data)
}

const getItemByIdRequest = (id: ID): Promise<CardPackageCategoryDTO | undefined> => {
  return axios
    .get(`${API_URL}GetCardPackageCategory?CardPackageCategoryIdList=${id}`)
    .then((response: AxiosResponse<CardPackageCategoriesQueryResponse>) => response.data.data?.[0])
}

const putItemRequest = (packageCategory: CardPackageCategoryDTO) => {
  const { cardPackageCategoryId } = packageCategory;
  delete packageCategory.cardPackageCount;
  const url = cardPackageCategoryId ? `${API_URL}UpdateCardPackageCategory` : `${API_URL}CreateCardPackageCategory`;
  return axios.post<CardPackageCategoryDTO>(url, {data: [packageCategory]})
}

const deleteItemRequest = (cardPackageCategoryId: ID): Promise<void> => {
  return axios.post(`${API_URL}DeleteCardPackageCategory`, { cardPackageCategoryId }).then(() => { })
}

export { getItemsRequest, getItemByIdRequest, putItemRequest, deleteItemRequest }
