/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useMemo, useState} from 'react'
import {ErrorData, KTSVG, errorHandler, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../auth'
import { CardDTO, CardTypeEnum } from '@/app/models/card'
import { GroupProvider, useGroup } from '../core/GroupProvider'
import Timer from '@/app/common/timer'
import { cardStepBackwardRequest, cardStepForwardRequest } from '../core/_requests'
import { AxiosError } from 'axios'
import moment from 'moment'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const DEFAULT_TIMEOUT_MS = 30000;

const Group = () => {
  const navigate = useNavigate()
  let { groupId } = useParams()
  const {saveTraineeAuth, setTrainee, trainee} = useAuth()
  const { groupData, storedReshuffleDate, setStoredReshuffleDate } = useGroup()
  const [usersOpened, setUsersOpened] = useState(false)
  const [cardsEnd, setCardsEnd] = useState(false)
  const [cardsFirst, setCardsFirst] = useState(true)
  const [stepTimeout, setStepTimeout] = useState<number | null>(null)
  const [stepEnabled, setStepEnabled] = useState(false)

  const [ tempLastCard, setTempLastCard ] = useState<CardDTO | undefined>(undefined)

  const [activeCard, setActiveCard] = useState<CardDTO | undefined>(undefined)
  const [isReShuffle, setIsReShuffle] = useState(false)
  const [isFlipped, setIsFlipped] = useState(false)

  const [error, setError] = useState<string | undefined>(undefined)

  

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }

    const nowMoment = moment().add(10, 'seconds').toISOString();
    setStoredReshuffleDate(nowMoment);
    handleCardNextClick(true);
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  useEffect(() => {
    if (groupData) {
      const reshuffleDate = groupData?.lastReshuffleDateTime;
      if(!reshuffleDate || !storedReshuffleDate || (new Date(reshuffleDate) < new Date(storedReshuffleDate))) return;

      if (storedReshuffleDate && new Date(storedReshuffleDate) < new Date(reshuffleDate)) {
        setIsReShuffle(true)
        setTimeout(() => {
          setStoredReshuffleDate(reshuffleDate)
          setCardsFirst(true);
          handleCardNextClick(true);
          setIsReShuffle(false)
        }, 3000)
      }
    }
  }, [groupData])

  const resetCardTimeout = () => {
    if (stepTimeout) clearTimeout(stepTimeout);
    setStepEnabled(false);

    const interval = groupData?.cardSteppLimit! * 1000 || DEFAULT_TIMEOUT_MS;
    const newTimeout: any = setTimeout(() => {
      setStepEnabled(true);
    }, interval);

    setStepTimeout(newTimeout);
  };

  const handleHomeClick = () => {
    saveTraineeAuth(undefined)
    setTrainee(undefined)
    navigate('/trainee/'+groupId+'/join');
  }

  const handleCardNextClick = async (force = false) => {
    if(!stepEnabled && !force) return;
    if(cardsEnd && !force) {
      setTempLastCard(activeCard);
      setIsFlipped(false);
      setCardsFirst(false);
      return setActiveCard(undefined);
    }
    resetCardTimeout();
    try {
      const { data: card, isEnd } = await cardStepForwardRequest(trainee?.studentId!)
      setCardsEnd(isEnd || false);
      if(activeCard !== undefined && !isEnd && !force)
        setCardsFirst(false);
      if(card === null) {
        setTempLastCard(activeCard);
      }
      setActiveCard(card || undefined)
      setIsFlipped(false)
    } catch (error) {
        const err = error as AxiosError<ErrorData>
        setError('Nem sikerült a kártya lekérése, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(err.response.status, err.response.data.errorResponse?.value?.errorInfo || '', err.response.data.errorResponse?.value?.errorDetails || '');
          setError(errorText)
          setTimeout(() => {
            setError("")
          }, 2500)
        }
    }
  }

  const handleCardPrevClick = async() => {
    if(cardsFirst || groupData?.isStepBackEnable === false) return;
    resetCardTimeout();
    if(cardsEnd) {
      setActiveCard(tempLastCard);
      setCardsEnd(false);
      return;
    }
    const { data: card, isFirst, errorResponse } = await cardStepBackwardRequest(trainee?.studentId!)
    setCardsFirst(isFirst || false);
    setActiveCard(card)
    setIsFlipped(false)

    if(errorResponse) {
      setError(errorResponse?.value?.errorDetails || 'Hiba történt a kártya betöltése közben');

      setTimeout(() => {
        setError(undefined);
      }, 3000);
    }
  }

  const handleCardFlipClick = () => {
    if (activeCard) {
      setIsFlipped((prev) => !prev)
    }
  }

  return (
    <GroupProvider>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
        {/* begin::Body */}
        <div className='d-none d-lg-flex flex-column w-lg-450px'>
          {/* begin::Form */}
          <div className='d-flex flex-center flex-column flex-lg-row-fluid h-100'>
            {/* begin::Wrapper */}
            <div className='trainee-sidebar'>
              <div className='trainee-sidebar__header'>
                <img alt='ESLCard' src={toAbsoluteUrl('/media/esl/logo.png')} className='h-75px' />
              </div>
              <div className='trainee-sidebar__content'>
                <div className='text-center mb-11'>
                  <h1 className='fw-bolder mb-5 text-uppercase text-white' style={{fontSize: 32}}>
                    {groupData?.name}
                  </h1>
                </div>
                <div className='esl-accordion'>
                  <div className={`esl-accordion__item ${usersOpened ? 'opened' : ''}`}>
                    <div
                      className='esl-accordion__header'
                      onClick={groupData?.groupXStudentList?.length ? () => setUsersOpened(!usersOpened) : undefined}
                    >
                      <div className='esl-accordion__arrow'>
                        <i className='bi bi-chevron-down'></i>
                      </div>
                      <div className='esl-accordion__header-content'>{groupData?.groupXStudentList?.length} felhasználó csatlakozott</div>
                    </div>
                    <div className={`esl-accordion__body`}>
                      {groupData?.groupXStudentList?.length !== 0 && (
                        <ul className='trainee-group-list'>
                          {groupData?.groupXStudentList?.map((groupXStudent) => (
                            <li className='trainee-group-list__item' key={groupXStudent?.student?.studentId}>{groupXStudent?.student?.studentName}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>

                <div className='d-grid mb-20'>
                  <button
                    type='button'
                    onClick={() => handleHomeClick()}
                    className='btn btn-esl-red btn-shadow btn-secondary'
                  >
                    <span className='indicator-label'>Vissza a csoport csatlakozáshoz</span>
                  </button>
                </div>

                {error && (
                  <div className='alert alert-danger mt-6'>
                    <div className='alert-text font-weight-bold'>{error}</div>
                  </div>
                )}
              </div>
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Form */}
        </div>
        {/* end::Body */}

        {/* begin::Aside */}
        <main className='trainee-content'>
          {/* begin::Content */}
          <div className='trainee-content__header'>
            <div className='row align-items-center mb-5'>
              <div className='col'>
                <h1 className='h3 mb-0 text-white' style={{fontSize: 18}}>
                  {groupData?.name}
                </h1>
              </div>
              <div className='col-auto'>
                <button className='btn px-1' onClick={() => handleHomeClick()}>
                  <KTSVG path='/media/esl/icons/logout.svg' className='svg-icon-2' />
                </button>
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col'>
                <OverlayTrigger
                    key='info-studentlist'
                    placement='bottom'
                    overlay={<Tooltip id='tooltip-studentlist'>
                      {groupData?.groupXStudentList?.length !== 0 && (
                        <ul className='trainee-group-list'>
                          {groupData?.groupXStudentList?.map((groupXStudent) => (
                            <li className='trainee-group-list__item' key={groupXStudent?.student?.studentId}>{groupXStudent?.student?.studentName}</li>
                          ))}
                        </ul>
                      )}
                    </Tooltip>}
                  >
                    <div className='d-inline-block'>{groupData?.groupXStudentList?.length} felhasználó </div>
                  </OverlayTrigger>
              </div>
              <div className='col-auto'><Timer callQueuedTime={groupData?.stopperStartTime || ''} /></div>
            </div>
          </div>
          <div className='trainee-content__body'>
            {isReShuffle && (
              <div className='trainee-card trainee-card--reshuffle'>
                <img src='/media/esl/ujrakeveres.gif' alt='Újrakeverés' />
              </div>
            )}
            {!isReShuffle && activeCard && (
              <div
                className={`trainee-card-wrapper ${
                  activeCard?.dC_CardTypeId === CardTypeEnum.FLASHCARD ? 'trainee-card-wrapper--can-flip' : ''
                } ${isFlipped ? 'flipped' : ''}`}
              >
                <div className='trainee-card' key={activeCard?.cardId}>
                  <div className='trainee-card__header'>
                    <div className='trainee-card__header-title'>{activeCard.name}{activeCard?.dC_CardTypeId === CardTypeEnum.FLASHCARD ? ' - A' : '' }</div>
                  </div>
                  <div
                    className='trainee-card__content'
                    dangerouslySetInnerHTML={{__html: activeCard.contentA ?? ''}}
                  ></div>
                  <div className='trainee-card__footer'>
                    {activeCard?.dC_CardTypeId === CardTypeEnum.FLASHCARD && (
                      <button
                        type='button'
                        className='btn color-esl-red p-3 d-none d-lg-inline-block'
                        onClick={() => handleCardFlipClick()}
                      >
                        <KTSVG
                          path='/media/esl/icons/card-turn.svg'
                          className='svg-icon--trainee-card-action color-esl-red'
                          svgClassName=''
                        />
                      </button>
                    )}
                  </div>
                </div>
                {activeCard?.dC_CardTypeId === CardTypeEnum.FLASHCARD && (
                  <div className='trainee-card trainee-card--back'>
                    <div className='trainee-card__header'>
                      <div className='trainee-card__header-title'>{activeCard.name} - B</div>
                    </div>
                    <div
                      className='trainee-card__content'
                      dangerouslySetInnerHTML={{__html: activeCard.contentB ?? ''}}
                    ></div>
                    <div className='trainee-card__footer'>
                        <button
                          type='button'
                          className='btn color-esl-red p-3 d-none d-lg-inline-block'
                          onClick={() => handleCardFlipClick()}
                        >
                          <KTSVG
                            path='/media/esl/icons/card-turn.svg'
                            className='svg-icon--trainee-card-action color-esl-red'
                            svgClassName=''
                          />
                        </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!isReShuffle && !activeCard && (
              <div className='trainee-card trainee-card--no-card'>
                <img src='/media/esl/snail.gif' alt='Nincs több kártya' />
              </div>
            )}
          </div>
          <div className='trainee-content__footer'>
            {isReShuffle && <>Újrakeverés - Kérjük, várj!</>}
            {!isReShuffle && !activeCard && <div className='d-inline-flex align-items-center'>
              { groupData?.isStepBackEnable && !cardsFirst && 
              <button
                  type='button'
                  className='btn btn-sm p-2 me-3'
                  onClick={() => handleCardPrevClick()}
                >
                  <KTSVG
                    path='/media/esl/icons/card-next.svg'
                    className='svg-icon--trainee-card-action'
                    svgClassName='rotate-180'
                  />
                </button>
              }
              Elfogytak a kártyák
            </div>}
            {!isReShuffle && activeCard && (
              <>
              { groupData?.isStepBackEnable && 
                <button
                  type='button'
                  className='btn p-3'
                  onClick={() => handleCardPrevClick()}
                  disabled={cardsFirst}
                >
                  <KTSVG
                    path='/media/esl/icons/card-next.svg'
                    className='svg-icon--trainee-card-action'
                    svgClassName='rotate-180'
                  />
                </button>
              }
                {activeCard?.dC_CardTypeId === CardTypeEnum.FLASHCARD && (
                  <button
                    type='button'
                    className='btn p-3 d-inline-block d-lg-none'
                    onClick={() => handleCardFlipClick()}
                  >
                    <KTSVG
                      path='/media/esl/icons/card-turn.svg'
                      className='svg-icon--trainee-card-action text-white'
                      svgClassName=''
                    />
                  </button>
                )}
                <button type='button' disabled={!stepEnabled} className='btn p-3' onClick={() => handleCardNextClick()}>
                  <KTSVG
                    path='/media/esl/icons/card-next.svg'
                    className='svg-icon--trainee-card-action'
                    svgClassName=''
                  />
                </button>
              </>
            )}
          </div>
          {/* end::Content */}
        </main>
        {/* end::Aside */}
      </div>
    </GroupProvider>
  )
}

export {Group}
