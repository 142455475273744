import axios, { AxiosResponse } from 'axios'
import { ID } from '../../../../../_metronic/helpers'
import { User, UserQueryResponse, UsersQueryResponse } from '@/app/models/user'


const API_URL = process.env.REACT_APP_ESL_API_URL + "Administration/"

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${API_URL + "GetUsers"}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}


const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .post(`${API_URL}GetUser`, { userId: id })
    .then((response: AxiosResponse<UserQueryResponse>) => response.data.data)
}

const putUser = (user: User) => {
  const { userId } = user
  const url = userId ? `${API_URL}UpdateUser` : `${API_URL}CreateUser`;
  return axios.post<User>(url, user)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.post(`${API_URL}DeleteUser`, {userId}).then(() => { })
}

export function registerRequest(user: User) {
  return axios.post(API_URL, user)
}

export { getUsers, deleteUser, getUserById, putUser }

