/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {QUERIES} from '../../../../_metronic/helpers'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {deleteItemRequest} from '../core/_requests'
import Swal from 'sweetalert2'
import { GroupDTO } from '@/app/models/group'

type Props = {
  data: GroupDTO
}

const ActionsCell: FC<Props> = ({data}) => {
  const {query, refetch} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const handleDeleteClick = async () => {
    const result = await Swal.fire({
      title: 'Biztos, hogy törölni szeretnéd a csoportot?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Törlés',
      cancelButtonText: 'Mégse',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary',
      },
    })

    if (result.isConfirmed) {
      await deleteItem.mutateAsync()
    }
  }

  const deleteItem = useMutation(() => deleteItemRequest(data?.groupId), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.GROUP_LIST}-${query}`])
      refetch();
    },
  })


  return (
    <>
      <a
        href='#'
        className='btn btn-icon btn-sm w-auto'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <i className="la la-ellipsis-v" style={{fontSize: 40}}></i>
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px p-3'
        data-kt-menu='true'
      >

        {/* begin::Menu item */}
        <div className='menu-item'>
          <a
            className='menu-link color-esl-red px-3'
            data-kt-users-table-filter='delete_row'
             onClick={handleDeleteClick}
          >
            Töröl
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {ActionsCell}
