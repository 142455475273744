import {FC, useEffect, useMemo, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {ErrorData, ID,  errorHandler} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import {useListView} from '../../core/ListViewProvider'
import {ListLoading} from '@/app/common/table/components/loading/ListLoading'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {AxiosError} from 'axios'
import {deleteCardRequest, saveCardsForCardPackage} from '../../core/_requests'
import {useMutation, useQueryClient} from 'react-query'
import Swal from 'sweetalert2'
import {CardDTO, CardTypeEnum, initialCardData} from '@/app/models/card'
import {DCList, useDCTables} from '@/app/providers/DCTablesProvider'
import FormikSelect from '@/app/modules/widgets/components/FormikSelect'
import { useAuth } from '@/app/modules/auth'
import { RightEnum, UserRoleEnum } from '@/app/models/user'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ImportCardsModal } from '../../import-cards-modal/ImportCardsModal'

type Props = {
  isLoading: boolean
  Data: CardDTO[] | null
  refetchCards: () => void
}

const EditCardsModalForm: FC<Props> = ({isLoading, Data, refetchCards}) => {
  const [success, setSuccess] = useState<boolean>(false)
  const [cardSide, setCardSide] = useState<string>('A');
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(0);
  const [importIsOpen, setImportIsOpen] = useState<boolean>(false);
  const {dcTables} = useDCTables()

  const { userRights, currentUserCan, currentUser } = useAuth()
  const characterLimit = userRights.includes(RightEnum.CardCharacterLimitUnlimited) ? null : userRights.includes(RightEnum.CardCharacterLimit200) ? 200 : 100;

  const {setItemForView, itemForView} = useListView()
  const {
    refetch,
  } = useQueryResponse()
  const queryClient = useQueryClient()

  const initItemValue = [{...initialCardData, cardPackageId: itemForView?.cardPackageId || 0}];

  const DataForEdit = Data ?? initItemValue

  useEffect(() => {
    if (Data) {
      formik.setFieldValue('cards', Data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data])

  const cancel = async(withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
      setItemForView?.(undefined)
    } else {
      const result = await Swal.fire({
        title: 'Vigyázat',
        text: 'Az el nem mentett módosítások törlődnek! Biztosan bezárod?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Bezárás',
        cancelButtonText: 'Mégse',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
      })

      if (result.isConfirmed) {
        setItemForView?.(undefined)
      }
    }
  }

  const formik = useFormik({
    initialValues: {cards: DataForEdit},
    validationSchema: Yup.object().shape({
      cards: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('A kártya neve kötelező'),
          contentA: Yup.string().required('A tartalom kötelező'),
          contentB: Yup.string().when('dC_CardTypeId', {
            is: (val: ID) => val?.toString() === CardTypeEnum.FLASHCARD.toString(),
            then: Yup.string().required('A B oldali tartalom kötelező'),
          }),
          dC_CardTypeId: Yup.string().required('A kártya típusa kötelező'),
          cardPackageId: Yup.string().required('A kártya csomagja kötelező'),
        })
      ),
    }),
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        const formData = Object.assign({cards: DataForEdit}, formik.values)

        const cardsToUpdate = formData.cards.filter((card: CardDTO) => card.cardId !== 0)
        const cardsToCreate = formData.cards.filter(card => card?.cardId === 0)

        if(cardsToCreate?.length > 0)
          await saveCardsForCardPackage(cardsToCreate, false)
        
        if(cardsToUpdate?.length > 0)
          await saveCardsForCardPackage(cardsToUpdate, true)

        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          cancel(true)
          resetForm()
        }, 2500)
      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Nem sikerült a mentés, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(err.response.status, err.response.data.errorResponse?.value?.errorInfo || '', err.response.data.errorResponse?.value?.errorDetails || '')
          setStatus(errorText)
          setTimeout(() => {
            setStatus('')
          }, 2500)
        }
      } finally {
        setSubmitting(true)
      }
    },
  })

  const addNewCard = () => {
    formik.setFieldValue('cards', [...formik.values.cards, initItemValue[0]])
    setCardSide('A')
    setSelectedCardIndex(formik.values.cards.length);
  }

  const handleImportClick = async () => {
      const result = await Swal.fire({
        title: 'Vigyázat',
        text: 'Az el nem mentett módosítások felülíródnak importálás során! Biztosan folytatod?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Tovább az importálásra',
        cancelButtonText: 'Mégse',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
      })
  
      if (result.isConfirmed) {
        setImportIsOpen(true)
      }
  }

  const handleDeleteClick = async (event: any, id: ID, index: number) => {
    event.preventDefault();
    event.stopPropagation();
    const result = await Swal.fire({
      title: 'Biztos, hogy törölni szeretnéd a kártyát?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Törlés',
      cancelButtonText: 'Mégse',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary',
      },
    })

    if (result.isConfirmed) {
      if(id === 0) {
        return formik.setFieldValue('cards', formik.values.cards.filter((_, i) => i !== index))
      }
      await deleteItem.mutateAsync(id)
    }
  }

  const deleteItem = useMutation((id: ID) => deleteCardRequest(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`edit-card-package-cards-${Data?.[0].cardPackageId}`])
      refetch();
    },
  })

  const ultimateEditorModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{ 'align': '' }, { 'align': 'center' }, { 'align': 'justify' }],
      ['link', 'image'],
      ['clean']
    ],
  }

  const freeUserEditorModules = {
    toolbar: [
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      ['clean']
    ],
  }

  const premiumUserEditorModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{ 'align': '' }, { 'align': 'center' }, { 'align': 'justify' }],
      ['link'],
      ['clean']
    ],
  }

  const editorModules = useMemo(() => {
    if(currentUser?.roleId === UserRoleEnum.FREE) {
      return freeUserEditorModules;
    }
    if(currentUser?.roleId === UserRoleEnum.PREMIUM) {
      return premiumUserEditorModules;
    }
    return ultimateEditorModules;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const editorFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'align',
    'link', 'image'
  ]

  const characterCountA = useMemo(() => {
    const content = formik.values.cards[selectedCardIndex as number]?.contentA;
    const strippedContent = content?.replace(/(<([^>]+)>)/gi, '');
    return strippedContent?.length;
  }, [formik.values.cards, selectedCardIndex])

  const characterCountB = useMemo(() => {
    const content = formik.values.cards[selectedCardIndex as number]?.contentB;
    const strippedContent = content?.replace(/(<([^>]+)>)/gi, '');
    return strippedContent?.length;
  }, [formik.values.cards, selectedCardIndex])

  const handleContentChange = (side: string, value: string) => {
    const strippedValue = value?.replace(/(<([^>]+)>)/gi, '');
    if(side === 'A') {
      if( characterLimit && strippedValue.length > characterLimit) {
        return;
      }
      formik.setFieldValue(`cards[${selectedCardIndex}].contentA`, value)
    } else if(side === 'B') {
      if( characterLimit && strippedValue.length > characterLimit) {
        return;
      }
      formik.setFieldValue(`cards[${selectedCardIndex}].contentB`, value)
    }
  }

  const handleCardClick = (index: number) => {
    setSelectedCardIndex(index)
    setCardSide('A')
  }

  const handleImportClose = () => {
    setImportIsOpen(false)
    refetch()
    refetchCards()
  }

  const handleEditorBlur = () => {
    const contentA = formik.values.cards[selectedCardIndex as number]?.contentA;
    const cardName = formik.values.cards[selectedCardIndex as number]?.name;

    if(!cardName) {
      const calculatedName = contentA?.replace(/(<([^>]+)>)/gi, '').split(' ').slice(0, 3).join(' ');
      formik.setFieldValue(`cards[${selectedCardIndex}].name`, calculatedName)
    }
  }

  return (
    <>
      <form
        id='kt_modal_edit_cards_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='row align-items-stretch'>
          <div className='col-12 col-md-6 col-lg-5'>
            <div className="cards-list-wrapper">
              <h3 className="h2 text-center pb-4">Kártyák</h3>
              <div className="cards-list-wrapper__content">
                <div className="cards-list-wrapper__content-inner">
                  <div className="row g-3 align-items-center">
                    { formik.values.cards?.length === 0 && (
                      <div className="col-12">
                        <div className="alert alert-info">Nincs kártya felvéve</div>
                      </div>
                    )}
                    { formik.values.cards?.map((card, index) => (
                      <div className="col-12 col-sm-6 col-md-12 col-lg-6" key={`${card?.cardId}-${index}`}>
                        <div className={`cards-list__card-item ${index === selectedCardIndex? 'selected' : ''} ${card?.cardId === 0? 'temp' : ''}`}>
                          <div className="row gx-0 align-items-center flex-nowrap flex-grow-1 mw-100" onClick={() => handleCardClick(index)}>
                            <div className="col-auto pe-2">
                              <i className="la la-file-alt fs-2"></i>
                            </div>
                            <div className="col text-truncate pe-2">
                              {card?.name || 'Új kártya'}
                            </div>
                            <div className="col-auto">
                              <button type='button' className="btn btn-icon btn-sm" onClick={(e) => handleDeleteClick(e, card?.cardId, index)}>
                                <i className="la la-times-circle fs-3"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )) }

                  </div>
                </div>
              </div>
              <div className="cards-list-wrapper__footer">
                <div className="row align-items-center">
                  <div className="col">
                    <button type='button' className="btn btn-primary w-100" onClick={addNewCard}>Új kártya</button>
                  </div>
                  <div className="col">
                    <button type='button' className="btn btn-secondary w-100" onClick={handleImportClick}>Importálás</button>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className='col-12 col-md'>
            {/* begin::Scroll */}
            <div className='d-flex flex-column me-n7 pe-7'>
              { selectedCardIndex !== null && formik.values.cards[selectedCardIndex] && (
                <div className='fv-row mb-8' key={selectedCardIndex}>
                  <div className='row g-4'>
                    <div className='col-12'>
                      <label className='fs-6 mb-2 required'>Kártya neve</label>
                      {/*  */}
                      <input
                        placeholder='Kártya neve'
                        onChange={formik.handleChange}
                        value={formik.values.cards[selectedCardIndex as number].name}
                        name={`cards[${selectedCardIndex}].name`}
                        type='text'
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {'is-invalid': formik.touched.cards?.[selectedCardIndex as number]?.name && (formik.errors.cards?.[selectedCardIndex as number] as CardDTO)?.name},
                          {
                            'is-valid': formik.touched.cards?.[selectedCardIndex as number]?.name && !(formik.errors.cards?.[selectedCardIndex as number] as CardDTO)?.name,
                          }
                        )}
                        autoComplete='off'
                        disabled={formik.isSubmitting || isLoading}
                      />
                      {formik.touched.cards?.[selectedCardIndex as number]?.name && ((formik.errors.cards?.[selectedCardIndex as number] as CardDTO)?.name) && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{(formik.errors.cards?.[selectedCardIndex as number] as CardDTO)?.name}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-12">
                      <FormikSelect
                        selectProps={{...formik.getFieldProps(`cards[${selectedCardIndex}].dC_CardTypeId`)}}
                        labelText={'Kártya típusa'}
                        options={currentUserCan(RightEnum.CardFlashcard)? dcTables?.dC_CardType : dcTables?.dC_CardType?.filter((cardType: DCList) => cardType.id !== CardTypeEnum.FLASHCARD)}
                        formikTouched={formik.touched.cards?.[selectedCardIndex as number]?.dC_CardTypeId}
                        formikErrors={((formik.errors.cards?.[selectedCardIndex as number] as CardDTO)?.dC_CardTypeId as string)}
                      />
                    </div>

                    <div className="col-12">
                      <div className="row align-items-center mb-2">
                        <div className="col">
                          <label className='fs-6 required'>Tartalom</label>
                        </div>
                        { formik.values.cards[selectedCardIndex as number].dC_CardTypeId?.toString() === CardTypeEnum.FLASHCARD.toString() && (
                        <div className="col-auto">
                          <div className="row gx-2 align-items-center flex-nowrap">
                            <div className="col-auto">Oldal:</div>
                            <div className="col-auto">
                              <button type='button' onClick={() => setCardSide('A')} className={`btn btn-sm btn-card-side-toggler ${cardSide === 'A'?'active':''}`}>
                                A
                              </button>
                            </div>
                            <div className="col-auto">
                              <button type='button' onClick={() => setCardSide('B')} className={`btn btn-sm btn-card-side-toggler ${cardSide === 'B'?'active':''}`}>
                                B
                              </button>
                            </div>
                          </div>
                        </div>
                        )}
                        <div className="col-auto">
                          <div>Karakter limit: {cardSide === 'A' ? characterCountA : characterCountB}/{characterLimit === null ? '∞' : characterLimit}</div>
                        </div>
                      </div>

                      <div className={`${cardSide === 'A'? 'd-block' : 'd-none'}`}>
                        <ReactQuill onBlur={handleEditorBlur} modules={editorModules} formats={editorFormats} theme="snow" readOnly={formik.isSubmitting || isLoading} value={formik.values.cards[selectedCardIndex as number]?.contentA} onChange={(value) => handleContentChange('A', value)}/>
                        
                        {formik.touched.cards?.[selectedCardIndex as number]?.contentA && ((formik.errors.cards?.[selectedCardIndex as number] as CardDTO)?.contentA) && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{(formik.errors.cards?.[selectedCardIndex as number] as CardDTO)?.contentA}</span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className={`${cardSide === 'B'? 'd-block' : 'd-none'}`}>
                        <ReactQuill onBlur={handleEditorBlur} modules={editorModules} formats={editorFormats} theme="snow" readOnly={formik.isSubmitting || isLoading} value={formik.values.cards[selectedCardIndex as number]?.contentB} onChange={(value) => handleContentChange('B', value)}/>
                    
                        {formik.touched.cards?.[selectedCardIndex as number]?.contentB && ((formik.errors.cards?.[selectedCardIndex as number] as CardDTO)?.contentB) && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{(formik.errors.cards?.[selectedCardIndex as number] as CardDTO)?.contentB}</span>
                            </div>
                          </div>
                        )}
                      </div>


                    </div>
                  </div>
                </div>
              )}

              { selectedCardIndex === null && (
                <div className="h1">Válasszon egy kártyát</div>
              )}
            </div>
            {/* end::Scroll */}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 offset-lg-6">
            {formik.status && (
              <div className='alert alert-danger mt-6'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
            {success && (
              <div className='mb-10 bg-light-success p-4 rounded mt-6'>
                <div className='text-success'>Sikeres mentés!</div>
              </div>
            )}

            {/* begin::Actions */}
            <div className='text-center pt-15'>
              <button
                type='reset'
                onClick={() => cancel()}
                className='btn btn-light me-3'
                data-kt-edit-cards-modal-action='cancel'
                disabled={formik.isSubmitting}
              >
                Mégse
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                data-kt-edit-cards-modal-action='submit'
                disabled={formik.isSubmitting || !formik.touched}
              >
                <span className='indicator-label'>Mentés</span>
                {(formik.isSubmitting) && (
                  <span className='indicator-progress'>
                    Kérem várjon...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>

        
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <ListLoading />}
      { importIsOpen && (
        <ImportCardsModal onClose={handleImportClose} />
      )}
    </>
  )
}

export {EditCardsModalForm}
