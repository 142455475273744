import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination } from './core/QueryResponseProvider'
import { KTCard } from '../../../_metronic/helpers'
import { EditOrCreateModal } from './view-modal/EditOrCreateModal'
import { Table } from '@/app/common/table/Table'
import { ListHeader } from '@/app/common/table/components/header/ListHeader'
import { ListToolbar } from './components/header/ListToolbar'
import { CustomHeader } from '@/app/common/table/columns/CustomHeader'
import { Column } from 'react-table'
import { ActionsCell } from './table/columns/ActionsCell'
import { CardPackageDTO, CardPackageTypeEnum } from '@/app/models/card'
import { useDCTables } from '@/app/providers/DCTablesProvider'
import { useEffect } from 'react'
import { EditCardsModal } from './edit-cards-modal/EditCardsModal'

const CardPackagesList = () => {
  const { itemForUpdate, itemForView, setItemForView } = useListView()
  const loadedData = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const pagination = useQueryResponsePagination()
  const { updateState, state } = useQueryRequest()
  const { getDcList } = useDCTables();

  useEffect(() => {
    getDcList(['dC_CardPackageType', 'dC_CardPackageAccessCategory', 'dC_CardType']);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickView = (data: CardPackageDTO) => {
    setItemForView?.(data);
  }

  const columns: ReadonlyArray<Column<CardPackageDTO>> = [
    {
      Header: (props) => <CustomHeader state={state} updateState={updateState} tableProps={props} title='ID' className='min-w-125px' />,
      accessor: 'cardPackageId',
      Cell: ({ ...props }) => props.data[props.row.index].cardPackageId,
    },
    {
      Header: (props) => <CustomHeader state={state} updateState={updateState} tableProps={props} title='Név' className='min-w-125px' />,
      accessor: 'name',
      Cell: ({ ...props }) => props.data[props.row.index].name,
    },
    {
      Header: (props) => (
        <CustomHeader state={state} updateState={updateState} tableProps={props} title='Kártyák száma' className='min-w-125px' />
      ),
      id: 'cardCount',
      Cell: ({ ...props }) => props.data[props.row.index].cardCount,
    },
    {
      Header: (props) => <CustomHeader state={state} updateState={updateState} tableProps={props} title='Létrehozó' className='min-w-125px' />,
      id: 'smallUserDTO',
      disableSortBy: true,
      Cell: ({ ...props }) => {
        if(props.data[props.row.index].dC_CardPackageTypeId === CardPackageTypeEnum.EXCLUSIVE) return <span className='badge badge-light-success'>EXCLUSIVE</span>;
        return props.data[props.row.index].smallUserDTO?.userName
      }
    },
    {
      Header: (props) => <CustomHeader state={state} updateState={updateState} tableProps={props} title='Kategória' className='min-w-125px' />,
      accessor: 'cardPackageCategory',
      disableSortBy: true,
      Cell: ({ ...props }) => props.data[props.row.index].cardPackageCategory?.name,
    },
    {
      Header: (props) => (
        <CustomHeader  state={state} updateState={updateState} tableProps={props} title='' className='text-end min-w-100px' />
      ),
      id: 'view',
      disableSortBy: true,
      Cell: ({ ...props }) => <button className='btn btn-icon btn-sm m-0 fs-5' onClick={() => handleClickView(props.data[props.row.index])}><i className='la la-file-alt fs-2'></i></button>,
    },
    {
      Header: (props) => (
        <CustomHeader  state={state} updateState={updateState} tableProps={props} title='Tevékenység' className='text-end min-w-100px' />
      ),
      id: 'actions',
      disableSortBy: true,
      Cell: ({ ...props }) => <ActionsCell data={props.data[props.row.index]} />,
    },
  ]

  return (
    <>
      <h1 className="h1 page-heading">Publikus kártyacsomagok</h1>
      <KTCard>
        <ListHeader showSearch={true} updateState={updateState} toolbarComponent={<ListToolbar/>} />
        <Table pagination={pagination} isLoading={isLoading} updateState={updateState} loadedData={loadedData} columns={columns} />
      </KTCard>
      {itemForUpdate !== undefined && <EditOrCreateModal />}
      {itemForView !== undefined && <EditCardsModal />}
    </>
  )
}

const CardPackagesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CardPackagesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { CardPackagesListWrapper }
