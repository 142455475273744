/* eslint-disable react/jsx-no-target-blank */
import { useAuth } from '../../../../app/modules/auth'
import { errorHandler, ID, isNotEmpty, KTSVG, selectUserRole, toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { useQuery } from 'react-query'
import { getUserById } from '../../../partials/layout/header-menus/user-modal/core/_requests'
import { AxiosError } from 'axios'
import { User } from '@/app/models/user'

const SidebarFooter = () => {
  const { currentUser, logout } = useAuth()
  const enabledQuery: boolean = isNotEmpty(currentUser)

  const {
    isLoading,
    data: user,
    error,
    refetch: refetchUser
  } = useQuery(
    `user-${currentUser?.userId}`,
    () => {
      return getUserById(currentUser?.userId as ID)
    },
    {
      cacheTime: 0,
      enabled:enabledQuery,
      refetchOnWindowFocus: false,
      onError: (error) => {
        const err = error as AxiosError

        if(err?.response?.status === 401){
          logout();
        }
        if (err?.response) {
          const errorText = errorHandler(err?.response?.status,"");
          // setStatus(errorText)
        }
        console.error(err)
      },
    }
  )


  const handleLogOut = () => {
    logout();
  }

  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <div
        className='btn btn-flex flex-start overflow-hidden text-nowrap h-50px w-100 sidebar-footer-wrapper'
        data-bs-toggle='tooltip'
        data-bs-trigger='hover'
        data-bs-dismiss-='click'
      >
        <div className='w-100'>
          <div
            className={'cursor-pointer symbol symbol-35px symbol-md-40px d-flex'}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <div className='symbol symbol-50px me-4'>
              <div className="sidebar-avatar">
                <KTSVG path='/media/esl/icons/user.svg' className='svg-icon-2 m-0 text-white' />
              </div>
            </div>
            <div className='d-flex flex-column justify-content-center flex-grow-1'>
              <div className='d-flex align-items-center fs-6 text-start text-white'>
                 {user?.firstName} {user?.lastName} { !user?.firstName && !user?.lastName && user?.emailAddress}
              </div>
              <span className='text-muted fs-7 text-start'>
                {selectUserRole(user?.roleId as number)} 
              </span>
            </div>
            <div className='d-flex flex-column justify-content-center'>
              <button className="btn p-1" onClick={handleLogOut}>
                <KTSVG path='/media/esl/icons/logout.svg' className='svg-icon-2 m-0 text-white' />
              </button>
            </div>
          </div>
          <HeaderUserMenu refetchUser={refetchUser} user={user as User} isLoading={isLoading} error={error}/>
        </div>
      </div>
    </div>
  )
}

export { SidebarFooter }
