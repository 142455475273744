/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useAuth} from '../../auth/core/Auth'
import {useNavigate, useParams} from 'react-router-dom'
import { useGroup } from '../core/GroupProvider'
import { GroupStatusEnum } from '@/app/models/group'

export function Lobby() {
  const navigate = useNavigate()
  let { groupId } = useParams()
  const {saveTraineeAuth, setTrainee} = useAuth()
  const { groupData } = useGroup()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(groupData?.dC_GroupStatusId === GroupStatusEnum.STARTED) {
      setLoading(true)
      setTimeout(() => {
        navigate('/trainee/'+groupId+'/group');
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData])

  const handleHomeClick = () => {
    saveTraineeAuth(undefined)
    setTrainee(undefined)
    navigate('/trainee/'+groupId+'/join');
  }

  return (
    <div className='form w-100 text-center'>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='fw-bolder mb-5 text-uppercase text-white' style={{fontSize: 32}}>
          {groupData?.name}
        </h1>
      </div>
      {/* begin::Heading */}

      <p className='fs-2 mb-20'>Sikeres csatlakozás!</p>

      <div className='trainee-sidebar__info-block mb-20'>
        {(groupData?.dC_GroupStatusId === GroupStatusEnum.CREATED || groupData?.dC_GroupStatusId === GroupStatusEnum.CLOSED) ? (
            <>
            A csoport még nem nyílt meg!
            <br />
            Kérjük várj, amíg az oktató megnyitja a csoportot!
            </>
        ) : (
            <>
            A csoport indítása folyamatban...
            </>
        )}
        
      </div>

      {/* begin::Action */}
      <div className='d-grid mb-20'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-esl-red btn-shadow btn-secondary'
          onClick={handleHomeClick}
          disabled={loading}
        >
          {!loading && <span className='indicator-label'>Vissza a csoport csatlakozáshoz</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Kérem várjon...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </div>
  )
}
