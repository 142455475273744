import axios, { AxiosResponse } from 'axios';
import { ID } from '../../../../_metronic/helpers'
import { CardPackagesQueryResponse } from '@/app/models/card'

const API_BASE_URL = process.env.REACT_APP_ESL_API_URL
const API_URL = API_BASE_URL + "CardPackage/"

const getItemsRequest = (query: string): Promise<CardPackagesQueryResponse> => {
  return axios
    .get(`${API_URL}GetCardPackages?${query}`)
    .then((d: AxiosResponse<CardPackagesQueryResponse>) => d.data)
}

const importItemRequest = (cardPackageId: ID): Promise<void> => {
  return axios.post(`${API_BASE_URL}Card/DuplicateCardPackageList`, { cardPackageIdList: [cardPackageId] }).then(() => { })
}

export { getItemsRequest, importItemRequest }
