import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

export function SuccessfullyRegistration() {


    return (
        <div id='successfull_registration_wrapper' className='p-8'>
            {/* begin::Title */}
            <h1 className='fw-bolder fs-2hx text-gray-800 mb-4 text-align-center'>Sikeres regisztráció!</h1>
            {/* end::Title */}

            {/* begin::Text */}
            <div className='fw-semibold fs-6 text-gray-800 mb-7 text-align-center'>Már be is léphet a felületre.</div>
            {/* end::Text */}

            {/* begin::Illustration */}
            <div className='mb-3 d-flex justify-content-center'>
                <img
                    src={toAbsoluteUrl('/media/illustrations/sigma-1/17.png')}
                    className='mw-100 mh-300px theme-light-show'
                    alt=''
                />
                <img
                    src={toAbsoluteUrl('/media/illustrations/sigma-1/17-dark.png')}
                    className='mw-100 mh-300px theme-dark-show'
                    alt=''
                />
            </div>
            {/* end::Illustration */}

            {/* begin::Link */}
            <div className='mb-0 d-flex justify-content-center mt-6'>
                <Link to='/dashboard' className='btn btn-sm btn-primary'>
                    Bejelentkezés
                </Link>
            </div>
            {/* end::Link */}
        </div>)
}
