import { initialCardPackageCategoryData } from '@/app/models/card'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'

const ListToolbar = () => {
  const {setItemForUpdate} = useListView()
  const openAddItemModal = () => {
    if(setItemForUpdate !== undefined)
      setItemForUpdate(initialCardPackageCategoryData)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={openAddItemModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Új Kategória
      </button>
      {/* end::Add user */}
    </div>
  )
}

export {ListToolbar}
