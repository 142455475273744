import { useQuery } from 'react-query'
import { isNotEmpty} from '../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { EditModalForm } from './content/EditModalForm'
import { getItemByIdRequest } from '../../my-card-packages/core/_requests'

const EditOrCreateModalFormWrapper = () => {
  const { itemForUpdate, setItemForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemForUpdate) && itemForUpdate?.cardPackageId !== 0

  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `card-packages-${itemForUpdate?.cardPackageId}`,
    () => {
      return getItemByIdRequest(itemForUpdate?.cardPackageId)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err) => {
        setItemForUpdate?.(undefined)
        console.error(err)
      },
    }
  )

  return <EditModalForm isLoading={isLoading} Data={user}/>
}

export { EditOrCreateModalFormWrapper }
