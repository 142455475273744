import { CardPackagesListFilter } from './ListFilter'

const ListToolbar = () => {
  return (
    <div className='d-flex justify-content-end' data-kt-card-packages-table-toolbar='base'>
      {/* <CardPackagesListFilter /> */}
    </div>
  )
}

export {ListToolbar}
