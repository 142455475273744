import {FC, useState, createContext, useContext} from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from '../../../../_metronic/helpers'
import { CardPackageAccessCategoryEnum, CardPackageTypeEnum } from '@/app/models/card'
import { UserRoleEnum } from '@/app/models/user'

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)
const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const stateExtension = {needFlags: {needUser: true}, cardPackage: {dC_CardPackageTypeIdList: [CardPackageTypeEnum.EXCLUSIVE], dC_CardPackageAccessCategoryIdList: [CardPackageAccessCategoryEnum.PUBLIC], createdRoleIdList: [UserRoleEnum.FREE, UserRoleEnum.ADMIN, UserRoleEnum.CONTENT_MANAGER]}}
  const [state, setState] = useState<QueryState>({...initialQueryRequest.state, ...stateExtension})

  const updateState = (updates: Partial<QueryState>) => {
   
    const updatedState = {...state, ...updates, ...stateExtension} as QueryState

    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
