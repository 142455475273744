import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { useAuth } from '../modules/auth'
import { RightEnum } from '../models/user'
import { PackageCategoriesListWrapper } from '../modules/package-categories'
import { MyCardPackagesListWrapper } from '../modules/my-card-packages'
import { GroupsWrapper } from '../modules/groups'
import { CardsListWrapper } from '../modules/cards'
import { CardPackagesListWrapper } from '../modules/card-packages'
import { PremiumCardPackagesListWrapper } from '../modules/premium-card-packages '

const PrivateRoutes = () => {
  const { currentUserCan } = useAuth()
  const UsersPage = lazy(() => import('../modules/users/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {!currentUserCan(RightEnum.UserEditing) ? (<Route path='auth/*' element={<Navigate to='/my-card-packages' />} />) :
          (<Route path='auth/*' element={<Navigate to='/users' />} />)}
        {/* Pages */}
        {currentUserCan(RightEnum.UserEditing) && <Route
          path='users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />}
        {currentUserCan(RightEnum.UserEditing) && <Route
          path='cards/*'
          element={
            <SuspensedView>
              <CardsListWrapper />
            </SuspensedView>
          }
        />}
        <Route
          path='my-card-packages/*'
          element={
            <SuspensedView>
              <MyCardPackagesListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='card-packages/*'
          element={
            <SuspensedView>
              <CardPackagesListWrapper />
            </SuspensedView>
          }
        />
        {currentUserCan(RightEnum.CopyPremiumPackages) && (
          <Route
          path='premium-card-packages/*'
          element={
            <SuspensedView>
              <PremiumCardPackagesListWrapper />
            </SuspensedView>
          }
        />
        )}
        <Route
          path='package-categories/*'
          element={
            <SuspensedView>
              <PackageCategoriesListWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='groups/*'
          element={
            <SuspensedView>
              <GroupsWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='cards/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
