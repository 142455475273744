import {FC, useState, createContext, useContext} from 'react'
import {
  GeneralListViewContextProps,
  WithChildren,
  initialGeneralOrderListView,
} from '../../../../_metronic/helpers'
import { CardPackageDTO } from '@/app/models/card'

const ListViewContext = createContext<GeneralListViewContextProps<CardPackageDTO>>(initialGeneralOrderListView as GeneralListViewContextProps<CardPackageDTO>)

const ListViewProvider: FC<WithChildren> = ({children}) => {
  const [itemForUpdate, setItemForUpdate] = useState<CardPackageDTO>()
  const [itemForView, setItemForView] = useState<CardPackageDTO>()
  const [orderModal, setOrderModal] = useState<boolean>(false)
  
  return (
    <ListViewContext.Provider
      value={{
        orderModal,
        setOrderModal,
        itemForUpdate,
        setItemForUpdate,
        itemForView,
        setItemForView,
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
