/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
import {RightEnum, UserRoleEnum} from '@/app/models/user'
import { useMemo } from 'react'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser, currentUserCan} = useAuth()

  const canViewPremiumPackages = useMemo(() => {
    if(!currentUser?.roleId || currentUser?.roleId === UserRoleEnum.FREE) return false
    return true
  }, [currentUser])

  return (
    <>
      {currentUserCan(RightEnum.UserEditing) && (
        <SidebarMenuItem to='/users/users-list' title='Felhasználók' fontIcon='la-users' />
      )}
      <SidebarMenuItem to='/my-card-packages' title='Saját kártyacsomagok' fontIcon='la-boxes' />
      <SidebarMenuItem to='/card-packages' title='Publikus kártyacsomagok' fontIcon='la-boxes' />
      {canViewPremiumPackages && (
        <SidebarMenuItem to='/premium-card-packages' title='Prémium kártyacsomagok' fontIcon='la-star' />
      )}
      {currentUserCan(RightEnum.CategoryEditor) && (
        <SidebarMenuItem to='/package-categories' title='Csomag kategóriák' fontIcon='la-cube' />
      )}
      <SidebarMenuItem to='/groups' title='Csoportok' fontIcon='la-users' />
    </>
  )
}

export {SidebarMenuMain}
