import {FC, useState, createContext, useContext, useMemo} from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from '../../../../_metronic/helpers'
import {CardPackageAccessCategoryEnum} from '@/app/models/card'
import {UserRoleEnum} from '@/app/models/user'
import {useAuth} from '../../auth'

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)
const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const {auth} = useAuth()
  const createdRoleIdList = useMemo(() => {
    let roleIdList: number[] = []
    switch (auth?.roleId) {
      case UserRoleEnum.ADMIN:
        roleIdList = [UserRoleEnum.ULTIMATE, UserRoleEnum.PREMIUM]
        break
      case UserRoleEnum.CONTENT_MANAGER:
        roleIdList = [UserRoleEnum.ULTIMATE, UserRoleEnum.PREMIUM]
        break
      case UserRoleEnum.ULTIMATE:
        roleIdList = [UserRoleEnum.ULTIMATE, UserRoleEnum.PREMIUM]
        break
      case UserRoleEnum.PREMIUM:
        roleIdList = [UserRoleEnum.PREMIUM]
        break
      default:
        roleIdList = []
        break
    }
    return roleIdList
  }, [auth])
  const stateExtension = {
    needFlags: {needUser: true},
    cardPackage: {
      dC_CardPackageAccessCategoryIdList: [CardPackageAccessCategoryEnum.PUBLIC],
      createdRoleIdList: createdRoleIdList,
    },
  }
  const [state, setState] = useState<QueryState>({...initialQueryRequest.state, ...stateExtension})

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates, ...stateExtension} as QueryState

    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
