import axios, { AxiosResponse } from 'axios';
import { ID } from '../../../../_metronic/helpers'
import { CardsQueryResponse } from '@/app/models/card'

const API_URL = process.env.REACT_APP_ESL_API_URL + "Card/"

const getItemsRequest = (query: string, userId?: ID): Promise<CardsQueryResponse> => {
  return axios
    .get(`${API_URL}GetCard?${query}${userId ? '&createdBy='+userId : ''}`)
    .then((d: AxiosResponse<CardsQueryResponse>) => d.data)
}

const deleteItemRequest = (cardId: ID): Promise<void> => {
  return axios.post(`${API_URL}DeleteCard`, { cardId }).then(() => { })
}

export { getItemsRequest, deleteItemRequest }
