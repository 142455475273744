import { useQuery } from 'react-query'
import { isNotEmpty} from '../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { getItemByIdRequest } from '../core/_requests'
import { EditGroupModalForm } from './content/EditGroupModalForm'

const EditGroupModalFormWrapper = () => {
  const { itemForUpdate, setItemForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemForUpdate) && itemForUpdate?.groupId !== 0

  const {
    isLoading,
    data,
    error,
  } = useQuery(
    `group-${itemForUpdate?.groupId}`,
    () => {
      return getItemByIdRequest(itemForUpdate?.groupId)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err) => {
        setItemForUpdate?.(undefined)
        console.error(err)
      },
    }
  )

  return <EditGroupModalForm isLoading={isLoading} Data={data}/>
}

export { EditGroupModalFormWrapper }
