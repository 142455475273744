/* eslint-disable jsx-a11y/anchor-is-valid */
import { useGroup } from '../core/GroupProvider'

export function Closed() {
  const { groupData } = useGroup()

  return (
    <div className='form w-100 text-center'>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='fw-bolder mb-5 text-uppercase text-white' style={{fontSize: 32}}>
          {groupData?.name}
        </h1>
      </div>
      {/* begin::Heading */}

      <p className='fs-2 mb-20'>A csoport zárva van!</p>

      <div className='trainee-sidebar__info-block mb-20'>
        Jelenleg nem tudsz csatlakozni.
      </div>

    </div>
  )
}
