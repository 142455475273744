/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo, createContext} from 'react'
import {useQuery} from 'react-query'
import {
  initialQueryState,
  PaginationState,
  QUERIES,
  QueryResponseContextProps,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../_metronic/helpers'
import {getAllCardPackageCategoriesSearchRequest, getAllFoldersSearchRequest, getItemsRequest} from './_requests'
import {useQueryRequest} from './QueryRequestProvider'
import { CardPackageDTO } from '@/app/models/card'
import { useAuth } from '../../auth'
import { UserRoleEnum } from '@/app/models/user'

type CardPackageQueryResponseContextProps<T> = QueryResponseContextProps<T> & {
  foldersListRefetch: () => void
  foldersListResponse: any
  cardPackageCategoriesListRefetch: () => void
  cardPackageCategoriesListResponse: any
}

const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '', foldersListRefetch: () => { }, foldersListResponse: [], cardPackageCategoriesListRefetch: () => { }, cardPackageCategoriesListResponse: [] }

const QueryResponseContext = createContext<CardPackageQueryResponseContextProps<CardPackageDTO>>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const { auth } = useAuth()
  
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.MY_CARD_PACKAGES}-${query}`,
    () => {
      return getItemsRequest(query, auth?.userId)
    },
    { keepPreviousData: true, refetchOnWindowFocus: false}
  )

  // getAllFoldersSearchRequest

  const {
    isFetching: foldersListIsFetching,
    refetch: foldersListRefetch,
    data: foldersListResponse,
  } = useQuery(
    `${QUERIES.FOLDERS_LIST}`,
    () => {
      const userId = auth?.userId
      return getAllFoldersSearchRequest('', userId)
    },
    {keepPreviousData: true, refetchOnWindowFocus: false}
  )

  //getAllCardPackageCategoriesSearchRequest

  const {
    isFetching: cardPackageCategoriesListIsFetching,
    refetch: cardPackageCategoriesListRefetch,
    data: cardPackageCategoriesListResponse,
  } = useQuery(
    `${QUERIES.CARD_PACKAGE_CATEGORIES_LIST}`,
    () => {
      return getAllCardPackageCategoriesSearchRequest()
    },
    {keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query, foldersListRefetch, foldersListResponse, cardPackageCategoriesListRefetch, cardPackageCategoriesListResponse}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
