import {FC, useState} from 'react'
import {ID} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import {useListView} from '../../core/ListViewProvider'
import {ListLoading} from '@/app/common/table/components/loading/ListLoading'
import {CardDTO, CardPackageDTO, CardTypeEnum} from '@/app/models/card'
import { getCardsByCardPackageIdRequest } from '@/app/modules/my-card-packages/core/_requests'
import { useMutation, useQuery } from 'react-query'
import { importItemRequest } from '../../core/_requests'
import { AxiosError } from 'axios'

type Props = {
  isLoading: boolean
  Data: CardPackageDTO | undefined
}

const EditCardsModalForm: FC<Props> = ({isLoading, Data}) => {
  const [success, setSuccess] = useState<boolean>(false)
  const [ activeCard, setActiveCard ] = useState<CardDTO | undefined>(undefined)
  const [cardSide, setCardSide] = useState<string>('A');
  const {setItemForView} = useListView()
  const { mutate: importMutate, isLoading: importIsLoading, isError: importIsError, error: importError } = useMutation((id: ID) => importItemRequest(id), {
    onSuccess: () => {
      setSuccess(true);

      setTimeout(() => {
        setSuccess(false);
        setItemForView?.(undefined);
      }, 2000);
    }
  })

  const cancel = () => {
    setItemForView?.(undefined)
  }

  const {
    isLoading: CardsIsLoading,
    data: CardsData,
    error: CardsError,
  } = useQuery(
    `edit-card-package-cards-${Data?.cardPackageId}`,
    () => {
      return getCardsByCardPackageIdRequest(Data?.cardPackageId)
    },
    {
      cacheTime: 0,
      enabled: !!Data?.cardPackageId,
      refetchOnWindowFocus: false,
      onError: (err) => {
        setItemForView?.(undefined)
        console.error(err)
      },
    }
  )

  return (
    <>
      <div
        id='kt_modal_edit_cards_form'
        className='form'
      >
        <div className='row align-items-stretch'>
          <div className='col-12 col-md'>
            <div className='row align-items-stretch mb-3'>
              <div className='col-6'>
                <div className='cards-list-wrapper px-0'>
                  <div></div>
                  <div className='cards-list-wrapper__content'>
                    <div className='cards-list-wrapper__content-inner'>
                      <div className={`cards-list__card-item selected`}>
                        <div className='row gx-0 align-items-center flex-nowrap flex-grow-1 mw-100'>
                          <div className='col-auto pe-2'>
                            <i className='la la-boxes fs-2 align-top'></i>
                          </div>
                          <div className='col text-truncate pe-2'>{Data?.name}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='cards-list-wrapper__footer'></div>
                </div>
              </div>
              <div className='col-6'>
                <div className='cards-list-wrapper px-0'>
                  <div></div>
                  <div className='cards-list-wrapper__content'>
                    <div className='cards-list-wrapper__content-inner'>
                      { CardsIsLoading && <ListLoading />}
                      { CardsData?.length === 0 && !CardsIsLoading && (
                        <div className='text-center'>Nincs kártya</div>
                      )}
                      {CardsData?.map((item: CardDTO) => (
                      <div onClick={() => setActiveCard(item)} className={`cards-list__card-item ${activeCard?.cardId === item?.cardId ? 'selected': ''}`} key={item?.cardId}>
                        <div className='row gx-0 align-items-center flex-nowrap flex-grow-1 mw-100'>
                          <div className='col-auto pe-2'>
                            <i className='la la-file-alt fs-2 align-top'></i>
                          </div>
                          <div className='col text-truncate pe-2'>{item?.name}</div>
                        </div>
                      </div>
                      ))}
                    </div>
                  </div>
                  <div className='cards-list-wrapper__footer'></div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-12 col-md'>
            {/* begin::Scroll */}
            <div className='d-flex flex-column me-n7 pe-7'>
              <h2 className="h2 text-center">
                Kártya előnézete
              </h2>
              { activeCard && (
                <div className='fv-row mb-8' key={activeCard?.cardId}>
                  <div className='row g-4'>
                    <div className='col-12'>
                      <label className='fs-6 mb-2'>Kártya neve</label>
                      {/*  */}
                      <input
                        placeholder='Kártya neve'
                        value={activeCard?.name}
                        readOnly
                        type='text'
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0'
                        )}
                        autoComplete='off'
                      />
                    </div>

                    <div className='col-12'>
                      <label className='fs-6 mb-2'>Kártya típusa</label>
                      <input
                        placeholder='Kártya típusa'
                        value={activeCard?.dC_CardType?.name}
                        readOnly
                        type='text'
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0'
                        )}
                        autoComplete='off'
                      />
                    </div>

                    <div className="col-12">
                      <div className="row align-items-center mb-2">
                        <div className="col">
                          <label className='fs-6'>Tartalom</label>
                        </div>
                        { activeCard.dC_CardTypeId === CardTypeEnum.FLASHCARD && (
                        <div className="col-auto">
                          <div className="row gx-2 align-items-center flex-nowrap">
                            <div className="col-auto">Oldal:</div>
                            <div className="col-auto">
                              <button type='button' onClick={() => setCardSide('A')} className={`btn btn-sm btn-card-side-toggler ${cardSide === 'A'?'active':''}`}>
                                A
                              </button>
                            </div>
                            <div className="col-auto">
                              <button type='button' onClick={() => setCardSide('B')} className={`btn btn-sm btn-card-side-toggler ${cardSide === 'B'?'active':''}`}>
                                B
                              </button>
                            </div>
                          </div>
                        </div>
                        )}
                      </div>

                      <div dangerouslySetInnerHTML={{__html: (cardSide === 'A' ? activeCard?.contentA : activeCard?.contentB) || ''}}></div>

                    </div>
                  </div>
                </div>
              )}

              { !activeCard && (
                <div className="h4 text-center">Válasszon egy kártyát</div>
              )}
            </div>
            {/* end::Scroll */}
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            {importIsError && (
              <div className='alert alert-danger mt-6'>
                <div className='alert-text font-weight-bold'>{(importError as AxiosError).message}</div>
              </div>
            )}
            {success && (
              <div className='mb-10 bg-light-success p-4 rounded mt-6'>
                <div className='text-success'>Sikeres importálás!</div>
              </div>
            )}

            {/* begin::Actions */}
            <div className='text-center pt-15'>
              <button
                type='reset'
                onClick={() => cancel()}
                className='btn btn-light me-3'
                data-kt-edit-cards-modal-action='cancel'
                disabled={importIsLoading}
              >
                Mégse
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                data-kt-edit-cards-modal-action='submit'
                onClick={() => importMutate(Data?.cardPackageId)}
                disabled={importIsLoading}
              >
                <span className='indicator-label'>Csomag importálása</span>
                {importIsLoading && (
                  <span className='indicator-progress'>
                    Kérem várjon...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* end::Actions */}
      </div>
      {(isLoading) && <ListLoading />}
    </>
  )
}

export {EditCardsModalForm}
