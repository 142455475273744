import { Navigate, Route, Routes, Outlet, useParams } from 'react-router-dom';
import { Join } from './components/Join';
import { TraineeLayout } from './TraineeLayout';
import { useAuth } from '../auth';
import { SetName } from './components/SetName';
import { Lobby } from './components/Lobby';
import { Group } from './components/Group';
import { Closed } from './components/Closed';
import { GroupProvider } from './core/GroupProvider';

const TraineePage = () => {
  const { trainee } = useAuth();
  const { groupId } = useParams();

  return (
    <Routes>
        <Route path=":groupId" element={<GroupProvider><Outlet /></GroupProvider>}>
            <Route path="group" element={<Group />} />
            <Route element={<TraineeLayout />}>
              <Route path="closed" element={<Closed />} />
              <Route path="set-name" element={<SetName />} />
              <Route path="lobby" element={<Lobby />} />
            </Route>
            <Route index element={<Navigate replace to={"trainee/join"} />} />
          </Route>
        <Route element={<TraineeLayout />}>
          <Route path="join" element={<Join />} />
          <Route index element={<Navigate replace to="/trainee/join" />} />
          <Route path="*" element={<Navigate replace to="/trainee/join" />} />
        </Route>
    </Routes>
  );
};

export { TraineePage };
