/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { ErrorData, errorHandler } from '../../../../_metronic/helpers'
import { AxiosError } from 'axios'
import { useAuth } from '../../auth/core/Auth'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { useGroup } from '../core/GroupProvider'
import { updateStudentRequest } from '../core/_requests'

const setNameSchema = Yup.object().shape({
    studentName: Yup.string()
      .min(3, 'Minimum 3 karakter')
      .required('Kötelező mező'),
  })
  
  const initialValues = {
    studentName: ''
  }

export function SetName() {

  const navigate = useNavigate();
  let { groupId } = useParams();
  const { saveTraineeAuth, setTrainee, trainee } = useAuth()
  const { groupData } = useGroup()

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: setNameSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const newStudent = {studentId: trainee?.studentId!, studentName: values.studentName}

        await updateStudentRequest(newStudent);

        saveTraineeAuth({...trainee, studentName: values.studentName})
        setTrainee({...trainee, studentName: values.studentName})
        navigate('/trainee/'+groupId+'/lobby');
      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Nem sikerült a név megadása, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(err.response.status, err.response.data.errorResponse?.value?.errorInfo || '', err.response.data.errorResponse?.value?.errorDetails || '');
          setStatus(errorText)
          setTimeout(() => {
            setStatus("")
          }, 2500)
        }
      } finally {
        setSubmitting(true)
        setLoading(false)
      }

    },
  })

  return (
    <form
      className='form w-100 text-center'
      onSubmit={formik.handleSubmit}
      noValidate
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='fw-bolder mb-5 text-uppercase text-white' style={{fontSize: 32}}>{groupData?.name}</h1>
      </div>
      {/* begin::Heading */}

      <p className="fs-2 mb-20">Csatlakozás csoporthoz</p>

      {/* begin::Form group */}
      <div className='fv-row mb-20'>
        <label className='form-label text-white fs-6'>Név</label>
        <input
          placeholder='Név'
          {...formik.getFieldProps('studentName')}
          className={clsx(
            'form-control form-control--trainee form-control--white bg-transparent',
            { 'is-invalid': formik.touched.studentName && formik.errors.studentName },
            {
              'is-valid': formik.touched.studentName && !formik.errors.studentName,
            }
          )}
          type='text'
        />
        {formik.touched.studentName && formik.errors.studentName && (

          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.studentName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-20'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-esl-red btn-shadow btn-secondary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Csatlakozás</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Kérem várjon...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
