import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'
import { useEffect } from 'react'
import { EditGroupModal } from './edit-group-modal/EditGroupModal'
import { useDCTables } from '@/app/providers/DCTablesProvider'
import { GroupItem } from './components/groupItem'
import { ListLoading } from '@/app/common/table/components/loading/ListLoading'
import { initialGroupData } from '@/app/models/group'

const GroupsList = () => {
  const { itemForUpdate, setItemForUpdate } = useListView()
  const loadedData = useQueryResponseData()
  const { isFetched } = useQueryResponse()
  const isLoading = useQueryResponseLoading()

  const { getDcList } = useDCTables();

  useEffect(() => {
    getDcList(['dC_GroupStatus']);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddClick = () => {
    setItemForUpdate?.(initialGroupData)
  }


  return (
    <>
    <div className="row">
      <div className="col">
        <h1 className="h1 page-heading">Csoportok</h1>
      </div>
      <div className="col-auto">
        <button className="btn btn-primary" disabled={loadedData.length === 5 && false} onClick={() => handleAddClick()}>Új csoport létrehozása</button>
      </div>
    </div>
      
      <div>
        {(isLoading && !isFetched) && <ListLoading/>}
        { (!isLoading || isFetched) && loadedData?.map((group) => (
          <GroupItem className="mb-4" key={group.groupId} group={group} />
        ))}
        { !isLoading && isFetched && loadedData.length === 0 && <div className="alert alert-info" role="alert">
          <div className="alert-text">Nincs csoport hozzáadva.</div>
        </div>}

      </div>
      {itemForUpdate !== undefined && <EditGroupModal />}
    </>
  )
}

const GroupsWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <GroupsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { GroupsWrapper }
