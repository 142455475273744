/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo, createContext} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  QueryResponseContextProps,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../_metronic/helpers'
import {getItemsRequest} from './_requests'
import {useQueryRequest} from './QueryRequestProvider'
import { CardDTO } from '@/app/models/card'
import { getUserById } from '../../users/users-list/core/_requests'
import { User } from '@/app/models/user'

type CardsQueryResponseContextProps<T> = QueryResponseContextProps<T> & {
  getUserRefetch: () => void
  getUserResponse: User | undefined
  getUserIsFetching: boolean
}

const initialCardsQueryResponse = { ...initialQueryResponse, getUserRefetch: () => { }, getUserResponse: undefined, getUserIsFetching: false }

const QueryResponseContext = createContext<CardsQueryResponseContextProps<CardDTO>>(initialCardsQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.CARDS_LIST}-${query}`,
    () => {
      return getItemsRequest(query)
    },
    {keepPreviousData: true, refetchOnWindowFocus: false, enabled: state.createdBy !== undefined}
  )

  const {
    isFetching: getUserIsFetching,
    refetch: getUserRefetch,
    data: getUserResponse,
  } = useQuery(
    [`${QUERIES.GET_USER_BY_ID}`, (state.createdBy as {createdBy: number | undefined} | undefined)?.createdBy],
    () => {
      const userId = (state.createdBy as any).createdBy
      if(!userId) return Promise.resolve(undefined);
      return getUserById(userId)
    },
    {keepPreviousData: true, refetchOnWindowFocus: false, enabled: state.createdBy !== undefined}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query, getUserIsFetching, getUserRefetch, getUserResponse}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
