import {KTSVG} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import Swal from 'sweetalert2'

const EditCardsModalHeader = () => {
  const {setItemForView, itemForView} = useListView()

  const handleCloseClick = async() => {
    const result = await Swal.fire({
      title: 'Vigyázat',
      text: 'Az el nem mentett módosítások törlődnek! Biztosan bezárod?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Bezárás',
      cancelButtonText: 'Mégse',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary',
      },
    })

    if (result.isConfirmed) {
      setItemForView?.(undefined)
    }
  }

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <div>
        <h2 className='fw-bolder'>Kártyák szerkesztése</h2>
        <p className="text-muted mb-0">{itemForView?.name}</p>
      </div>
      
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-edit-cards-modal-action='close'
        onClick={() => handleCloseClick()}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {EditCardsModalHeader}
