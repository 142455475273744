/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {KTSVG, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteItemRequest, duplicateItemRequest} from '../../core/_requests'
import Swal from 'sweetalert2'
import { CardPackageDTO } from '@/app/models/card'

type Props = {
  data: CardPackageDTO
}

const ActionsCell: FC<Props> = ({data}) => {
  const {setItemForUpdate, setItemForView} = useListView()
  const {query, refetch} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemForUpdate?.(data)
  }

  const openCardsModal = () => {
    setItemForView?.(data)
  }

  const handleDeleteClick = async () => {
    const result = await Swal.fire({
      title: 'Biztos, hogy törölni szeretnéd a csomagot?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Törlés',
      cancelButtonText: 'Mégse',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary',
      },
    })

    if (result.isConfirmed) {
      await deleteItem.mutateAsync()
    }
  }

  const deleteItem = useMutation(() => deleteItemRequest(data?.cardPackageId), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.MY_CARD_PACKAGES}-${query}`])
      refetch();
    },
  })

  const duplicateItem = useMutation(() => duplicateItemRequest(data?.cardPackageId), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.MY_CARD_PACKAGES}-${query}`])
    },
  })


  return (
    <>
      <a
        href='#'
        className='btn btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px p-3'
        data-kt-menu='true'
      >

        {/* begin::Menu item */}
        <div className='menu-item'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Szerkesztés
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item'>
          <a className='menu-link px-3' onClick={openCardsModal}>
            Kártyák
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item'>
          <a className='menu-link px-3' onClick={() => duplicateItem.mutateAsync()}>
            Duplikálás
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item'>
          <a
            className='menu-link color-esl-red px-3'
            data-kt-users-table-filter='delete_row'
             onClick={handleDeleteClick}
          >
            Töröl
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {ActionsCell}
